<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import ModalComponent from '@components/ModalComponent.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { CheckIcon } from '@heroicons/vue/24/solid';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { sleep } from '@utils/sleep';
import { CreateCameraTokenResponse } from '@gabrielcam/api-client';

const PAUSE_UI = 1000;
const PROBE_INTERVAL = 2000;

enum states {
  PROBING = 'PROBING',
  SENDING_BOOT = 'SENDING_BOOT',
  AWAITING_BOOT = 'AWAITING_BOOT',
  LAUNCHING = 'LAUNCHING',
  ERROR = 'ERROR',
}

const completedStates = ref<states[]>([]);
const currentState = ref<states | null>();
const launchErrorMessage = ref<string>();
const apiErrorMessage = ref<string>();
const tokenInformation = ref<CreateCameraTokenResponse>();
const pollingInterval = ref();

const props = defineProps({
  camera: { type: Object as PropType<client.Camera>, required: true },
  onClose: { type: Function, required: true}
});

/**
 * Initialises the connection to a remote camera device and manages the state of its boot sequence.
 *
 * This function performs the following tasks:
 * 1. Requests an authentication token for the camera device via the API.
 * 2. Sets a local authentication URL if running on localhost, enabling local testing.
 * 3. Probes the device URL to check if the device is accessible and responsive.
 * 4. If the device is available, simulates the boot sequence steps and launches the device.
 * 5. If the device is not available, sends a boot command and continues to probe the device
 *    at regular intervals until it becomes accessible, at which point it launches the device.
 *
 * State and UI Feedback:
 * - Updates UI states (`completedStates`, `currentState`) to reflect the progress of each step.
 * - Implements a polling mechanism to repeatedly check device availability when in the boot sequence.
 *
 * @returns {Promise<void>} Resolves when the device has been successfully launched or when
 *                          initialisation has completed without further required actions.
 */
const init = async (): Promise<void> => {
  try {
    // Get details from API
    tokenInformation.value = await client.createCameraToken({ cameraId: props.camera.id });

    // Conditionally set deviceUrl if on localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      tokenInformation.value.deviceUrl = 'http://localhost:5174/auth';
    }

  } catch (error) {
    console.error('Unable to retrieve device information from the Server:', error);
    apiErrorMessage.value = 'Unable to retrieve device information from the Server';
    return;
  }

  // Check if the device responds
  if (await probe(tokenInformation.value.deviceUrl, states.PROBING)) {
    completedStates.value.push(states.PROBING);
    currentState.value = null;

    // Device is up, simulate steps
    completedStates.value.push(states.PROBING);
    // Always send a boot, just to make sure the device isn't shutting down soon
    await sendBootCommand();
    currentState.value = states.AWAITING_BOOT;
    await sleep(PAUSE_UI);
    completedStates.value.push(states.AWAITING_BOOT);
    // Launch
    await launch(tokenInformation.value);
    return;
  }
  completedStates.value.push(states.PROBING);
  currentState.value = null;

  // It's not up so send wakeup
  await sendBootCommand();

  // Probe on repeat until device is up
  await probe(tokenInformation.value.deviceUrl, states.AWAITING_BOOT)
  pollingInterval.value = setInterval(async () => {
    const deviceUrl = tokenInformation.value?.deviceUrl;
    if (deviceUrl && await probe(deviceUrl, states.AWAITING_BOOT)) {
      completedStates.value.push(states.AWAITING_BOOT);
      currentState.value = null;
      clearInterval(pollingInterval.value);
      await launch(tokenInformation.value!)
      return;
    }
  }, PROBE_INTERVAL);
}

const probe = async (url: string, probeState: states.PROBING | states.AWAITING_BOOT): Promise<boolean> => {
  currentState.value = probeState;
  await sleep(PAUSE_UI)

  let online = false;
  try {
    const response = await fetch(url);
    if (response.ok) online = true;
  } catch { }

  return online;
}

const sendBootCommand = async (): Promise<void> => {
  currentState.value = states.SENDING_BOOT;
  client.createCameraByIdWakeupCommand({ cameraId: props.camera.id })
  await sleep(PAUSE_UI)
  completedStates.value.push(states.SENDING_BOOT);
  currentState.value = null;
}

let configurator: Window | null;
const launch = async (tokenInformation: CreateCameraTokenResponse): Promise<void> => {
  currentState.value = states.LAUNCHING;
  await sleep(PAUSE_UI);

  if (!configurator) {
    configurator = window.open(tokenInformation.deviceUrl, 'configurator');
  }

  // Pause to ensure the opened window has the time to load before sending a message.
  await sleep(3000);

  configurator!.postMessage(JSON.parse(JSON.stringify(tokenInformation)), tokenInformation.deviceUrl);
}

const messageListener = (event: MessageEvent): void => {
  if (tokenInformation.value && !tokenInformation.value.deviceUrl.startsWith(event.origin)) return;

  if (event.data.status === 'success') {
    completedStates.value.push(states.LAUNCHING)
    currentState.value = undefined;
  } else if (event.data.status === 'error') {
    completedStates.value.push(states.LAUNCHING)
    currentState.value = states.ERROR;
    launchErrorMessage.value = event.data.message;
  }
}

async function closeModal(): Promise<void> {
  props.onClose()
}

onMounted(() => {
  init();
  window.addEventListener("message", messageListener);
})

onUnmounted(() => {
  if (pollingInterval.value) clearInterval(pollingInterval.value);
  window.removeEventListener("message", messageListener);
  console.log('unmounting')
});

const sendingBootCommandClass = computed(() => currentState.value === states.SENDING_BOOT ? 'progress-icon--in-progress' : 'progress-icon--pending');
const probingClass = computed(() => currentState.value === states.PROBING ? 'progress-icon--in-progress' : 'progress-icon--pending');
const awaitingBootClass = computed(() => currentState.value === states.AWAITING_BOOT ? 'progress-icon--in-progress' : 'progress-icon--pending');
const launchingClass = computed(() => currentState.value === states.LAUNCHING ? 'progress-icon--in-progress' : 'progress-icon--pending');
</script>

<template>
  <ModalComponent :visible="true"
                  :heading-title="'Launching Configurator'"
                  @on-close="closeModal">
    <template v-if="apiErrorMessage" #modal-content>
      <span class="message-error">
        {{ apiErrorMessage }}
      </span>
    </template>
    <template v-else #modal-content>
      <div>
        <ArrowPathIcon v-if="currentState === states.PROBING || !completedStates.includes(states.PROBING)" aria-label="Probing camera" :class="probingClass" />
        <CheckIcon v-if="completedStates.includes(states.PROBING)" aria-label="Probing camera" class="progress-icon--complete" />
        Probing camera
      </div>
      <div>
        <ArrowPathIcon v-if="currentState === states.SENDING_BOOT || !completedStates.includes(states.SENDING_BOOT)" aria-label="Sending boot command" :class="sendingBootCommandClass" />
        <CheckIcon v-if="completedStates.includes(states.SENDING_BOOT)" aria-label="Sending boot command" class="progress-icon--complete" />
        Sending boot command
      </div>
      <div>
        <ArrowPathIcon v-if="currentState === states.AWAITING_BOOT || !completedStates.includes(states.AWAITING_BOOT)" aria-label="Device booted" :class="awaitingBootClass" />
        <CheckIcon v-if="completedStates.includes(states.AWAITING_BOOT)" aria-label="Device booted" class="progress-icon--complete" />
        Awaiting boot
      </div>
      <div>
        <ArrowPathIcon v-if="currentState === states.LAUNCHING || !completedStates.includes(states.LAUNCHING)" aria-label="Launching configurator" :class="launchingClass" />
        <CheckIcon v-if="completedStates.includes(states.LAUNCHING) && currentState !== states.ERROR" aria-label="Launching configurator" class="progress-icon--complete" />
        <XMarkIcon v-if="currentState === states.ERROR" aria-label="Launching configurator" class="progress-icon--failed" />
        Launching configurator
        <span v-if="launchErrorMessage" class="progress-icon--failed">
          ({{ launchErrorMessage }})
        </span>
      </div>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;


.progress-icon {
  width: 1.3rem;
  height: 1.2rem;


  &--pending {
    width: 1.3rem;
    height: 1.2rem;
    color: $black-opacity-25;
  }

  &--failed {
    width: 1.3rem;
    height: 1.2rem;
    color: $red-900;
  }

  &--in-progress {
    width: 1.3rem;
    height: 1.2rem;
    color: $orange-800;
    animation: rotate-360 0.75s linear infinite;
  }

  &--complete {
    width: 1.3rem;
    height: 1.2rem;
    color: $green-800;
  }
}
</style>
