<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  isAbsolutePositioned?: boolean;
  loadingText?: string;
}

const props = defineProps<Props>();

// Default State
const defaultLoadingText = "Loading";

const wrapperClass = computed(() => {
  return props.isAbsolutePositioned ? 'loading__wrapper' : 'loading';
});

const loadingText = computed(() => props.loadingText || defaultLoadingText);
</script>

<template>
  <div :class="wrapperClass">
    <div class="loading__content">
      <p class="loading__content--text">
        {{ loadingText }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.loading {
  width: 100%;

  &__content {
    position: relative;
    padding: 60px 0 120px;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin: -35px -20px;
      content: '';
      background-image: url('/src/assets/heroIcons/loading.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      animation: rotate-360 2s linear infinite;
    }

    &--text {
      position: relative;
      top: 60px;
      text-align: center;
      user-select: none;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__content {
      padding: 60px 60px 120px;
      background: $black-opacity-25;
      backdrop-filter: blur(3px);
      border-radius: 10px;

      &--text {
        color: $neutral-200;
      }
    }
  }
}
</style>
