<script setup lang="ts">
import { computed } from 'vue';

// Define Interface
interface ButtonContainerProps {
  justifyContent?: 'flex-start' | 'flex-end' | 'space-between';
  isMobileColumnReverse?: boolean;
  hasBottomMargin?: boolean;
}

// Define Props
const props = withDefaults(defineProps<ButtonContainerProps>(), {
  justifyContent: 'flex-end',
  isMobileColumnReverse: true,
  hasBottomMargin: false,
});

// Define Defaults
const justifyContentClass = computed(() => props.justifyContent || 'flex-end');
</script>

<template>
  <div :class="['button-actions', { 'button-actions--mobile-column-reverse': isMobileColumnReverse }, justifyContentClass, { 'margin-bottom': hasBottomMargin }]">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.button-actions {
  display: flex;
  gap: 20px;

  &--mobile-column-reverse {
    flex-direction: column-reverse;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.margin-bottom {
    margin-bottom: $margin-bottom;
  }
}
</style>
