<script setup lang="ts">
const props = defineProps<{
  videoSrc: string;
}>();
</script>

<template>
  <div class="video-wrapper">
    <iframe id="stream-player"
            class="video-wrapper__iframe"
            :src="props.videoSrc"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            :allowfullscreen="true" />
  </div>
</template>

<style lang="scss" scoped>
.video-wrapper {
  line-height: 0;

  &__iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    border: none;
  }
}
</style>
