<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { getIconComponent } from '@utils/heroIconsMap';
import { useMobileDetection } from '@utils/isMobile';
import { useApplicationStore } from '@stores/application';
import { NavItem, useNavStore } from '@stores/navMenu';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import ButtonComponent from '@components/ButtonComponent.vue';

const applicationStore = useApplicationStore();
const { activeUser } = storeToRefs(applicationStore);
const navStore = useNavStore();
const { navItems } = storeToRefs(navStore);
const { organisationList, activeOrganisation } = storeToRefs(applicationStore);
const route = useRoute();
const selectedOrganisation = ref<client.Organisation>(activeOrganisation.value!);
const mobileNavOpen = ref<boolean>(false);
const { isMobile } = useMobileDetection();
const isOrgDropdownOpen = ref(false);

// Filter nav items based on permissions
const filteredNavItems = computed(() => {
  return navItems.value
    .filter((item) => {
      return item.viewPermission === undefined || item.viewPermission;
    })
    .map((item) => {
      if (item.children) {
        const newChildren = item.children.filter((child) => child.viewPermission === undefined || child.viewPermission);
        // Create a new item with the filtered children
        return { ...item, children: newChildren };
      }
      return item;
    });
});

// Toggle mobile nav and pass state to navStore
function toggleMobileNav(): void {
  // Check if mobile
  if (isMobile) {
    mobileNavOpen.value = !mobileNavOpen.value;
    const appContainer = document.getElementById('appContainer');
    if (appContainer) {
      if (mobileNavOpen.value) {
        appContainer.classList.add('mobile-nav-open');
      } else {
        appContainer.classList.remove('mobile-nav-open');
      }
    } else {
      console.error('Ensure element id "appContainer" exists in the DOM for the mobile nav to work');
    }
  }
}

// Close mobile nav when a route is clicked
function mobileLink(): void {
  setTimeout(() => {
    toggleMobileNav();
  }, 200);
}

async function organisationSelected(): Promise<void> {
  activeOrganisation.value = await client.getOrganisationById({ organisationId: selectedOrganisation.value!.id });
  // Always redirect to /views because viewId's are specific to organisations
  location.replace('/views');
}

// Handle clicks outside the sidebar
function handleOutsideClick(event: MouseEvent): void {
  const sidebar = document.querySelector('.app--sidebar');

  if (sidebar && !sidebar.contains(event.target as Node)) {
    toggleMobileNav();
  }
}

// Stop body scrolling when the sidebar is open
watch(mobileNavOpen, (newValue) => {
  if (newValue) {
    document.body.classList.add('no-scroll');
    document.addEventListener('click', handleOutsideClick);
  } else {
    document.body.classList.remove('no-scroll');
    document.removeEventListener('click', handleOutsideClick);
  }
});

// Ensure IDs are sanitized
function sanitizeId(title: string): string {
  return title.replace(/[^a-zA-Z0-9-_:.]/g, '-');
}

/**
 * Opens the parent menus based on the current route.
 *
 * This function iterates through the filtered navigation items and closes any open menus that do not match the current route.
 * It then opens the parent menu whose child matches the current route.
 *
 * @param {string} currentRoute - The current route path.
 */
function openParentMenus(currentRoute: string): void {
  // Close all non-relevant open menus
  filteredNavItems.value.forEach((navItem) => {
    if (navItem.isOpen && !(navItem.children && navItem.children.some((child) => currentRoute.startsWith(child.to || '')))) {
      navItem.isOpen = false;
      nextTick(() => {
        const submenuElement = document.querySelector(`#subnav-${sanitizeId(navItem.title?.toLowerCase() || '')}`) as HTMLElement;
        const iconContainerElement = document.querySelector(`#icon-container-${sanitizeId(navItem.title?.toLowerCase() || '')}`);

        if (submenuElement) {
          submenuElement.style.maxHeight = '0';
          setTimeout(() => submenuElement.style.display = 'none', 300);
        }

        if (iconContainerElement) {
          const iconElement = iconContainerElement.querySelector('svg') as SVGElement;
          iconElement.classList.remove('rotate-180');
        }
      });
    }
  });

  // Open the parent menu whose child matches the current route
  filteredNavItems.value.forEach((navItem) => {
    if (navItem.children && navItem.children.some((child) => currentRoute.startsWith(child.to || ''))) {
      if (!navItem.isOpen) {
        navItem.isOpen = true;
        nextTick(() => {
          const submenuElement = document.querySelector(`#subnav-${sanitizeId(navItem.title?.toLowerCase() || '')}`) as HTMLElement;
          const iconContainerElement = document.querySelector(`#icon-container-${sanitizeId(navItem.title?.toLowerCase() || '')}`);

          if (submenuElement) {
            submenuElement.style.display = 'flex';
            setTimeout(() => submenuElement.style.maxHeight = `${submenuElement.scrollHeight}px`, 50);
          }

          if (iconContainerElement) {
            const iconElement = iconContainerElement.querySelector('svg') as SVGElement;
            iconElement.classList.add('rotate-180');
          }
        });
      }
    }
  });
}

// Set the main menu on component mount
navStore.setMainMenu();

// Open any parent menus on component mount
onMounted(() => {
  setTimeout(() => {
    openParentMenus(route.path);
  }, 100);
});

// Watch for route changes to open parent menus dynamically based on the route
watch(route, (newRoute) => {
  openParentMenus(newRoute.path);
});

// Remove event listener when component is destroyed
onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick);
});

function toggleOrgDropdown(state: boolean): void {
  isOrgDropdownOpen.value = state;
}
/**
 * Toggles the dropdown menu for a given navigation item.
 *
 * @param {NavItem} navItem - The navigation item to toggle the dropdown for.
 */
function toggleDropdown(navItem: NavItem): void {
  const isOpen = !navItem.isOpen;
  navItem.isOpen = isOpen;

  nextTick(() => {
    const submenuElement = document.querySelector(`#subnav-${sanitizeId(navItem.title?.toLowerCase() || '')}`) as HTMLElement;
    const iconContainerElement = document.querySelector(`#icon-container-${sanitizeId(navItem.title?.toLowerCase() || '')}`);

    if (submenuElement) {
      submenuElement.style.transition = 'max-height 0.3s ease';

      if (isOpen) {
        submenuElement.style.display = 'flex';
        submenuElement.style.maxHeight = `${submenuElement.scrollHeight}px`;
      } else {
        submenuElement.style.maxHeight = '0';
        setTimeout(() => submenuElement.style.display = 'none', 300);
      }
    }

    if (iconContainerElement) {
      const iconElement = iconContainerElement.querySelector('svg') as SVGElement;
      isOpen ? iconElement.classList.add('rotate-180') : iconElement.classList.remove('rotate-180');
    }
  });
}
</script>

<template>
  <header>
    <router-link class="header-logo"
                 data-title="views"
                 :to="'/' + PageNames.Views">
      GabrielCAM
    </router-link>
  </header>

  <div class="sidebar-container">
    <router-link aria-label="GabrielCAM"
                 class="app--sidebar-logo app--sidebar-logo-open"
                 data-title="views"
                 :to="'/' + PageNames.Views" />

    <ButtonComponent :aria-expanded="mobileNavOpen"
                     aria-controls="menu-container"
                     aria-label="Toggle Menu"
                     :variant="ButtonVariant.Transparent"
                     class="menu-toggle"
                     @click="toggleMobileNav">
      <component :is="getIconComponent(IconName.Bars4Icon as IconName, IconStyle.Solid as IconStyle)"
                 v-show="!mobileNavOpen"
                 aria-hidden="true" />
      <component :is="getIconComponent(IconName.XMarkIcon as IconName, IconStyle.Solid as IconStyle)"
                 v-show="mobileNavOpen"
                 aria-hidden="true" />
    </ButtonComponent>

    <nav id="menu-container"
         class="menu-container">
      <ul class="menu menu-primary">
        <li v-if="organisationList && organisationList.length === 1"
            class="menu-organisation">
          {{ activeOrganisation?.name }}
        </li>

        <v-select v-if="organisationList && organisationList.length > 1"
                  v-model="selectedOrganisation"
                  :clearable="false"
                  :searchable="false"
                  :options="organisationList"
                  class="menu-organisation-select"
                  label="name"
                  placeholder="Name"
                  @option:selected="organisationSelected"
                  @search:focus="toggleOrgDropdown(true)"
                  @search:blur="toggleOrgDropdown(false)">
          <template #open-indicator>
            <ChevronDownIcon :class="{ 'rotate-180': isOrgDropdownOpen }" class="arrow-icon" />
          </template>
        </v-select>

        <li v-for="item in filteredNavItems"
            :key="item.title">
          <template v-if="item.separator">
            <hr>
          </template>
          <template v-else-if="item.children">
            <template v-if="item.isDropdown !== false">
              <button class="menu-dropdown" @click="toggleDropdown(item)">
                <component :is="getIconComponent(item.icon as IconName, item.iconStyle as IconStyle)" v-if="item.icon" class="menu-icon" />
                <span class="menu-header-text">{{ item.title }}</span>
                <span :id="'icon-container-' + sanitizeId(item.title?.toLowerCase() as string)" class="icon-container">
                  <ChevronDownIcon class="arrow-icon" />
                </span>
              </button>

              <ul v-show="item.isOpen"
                  :id="`subnav-${sanitizeId(item.title?.toLowerCase() as string)}`"
                  class="submenu"
                  style="display: none; max-height: 0; overflow: hidden;">
                <li v-for="child in item.children" :key="child.title">
                  <template v-if="child.to">
                    <router-link :data-title="child.title"
                                 :to="child.to"
                                 class="menu-item"
                                 @click="mobileLink">
                      <component :is="getIconComponent(child.icon as IconName, item.iconStyle as IconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </router-link>
                  </template>

                  <template v-else>
                    <div class="menu-item">
                      <component :is="getIconComponent(child.icon as IconName, item.iconStyle as IconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </div>
                  </template>
                </li>
              </ul>
            </template>
            <template v-else>
              <template v-if="item.to">
                <router-link :to="item.to"
                             class="menu-header"
                             @click="mobileLink">
                  <component :is="getIconComponent(item.icon as IconName, item.iconStyle as IconStyle)"
                             v-if="item.icon"
                             class="menu-icon" />
                  <span class="menu-header-text">{{ item.title }}</span>
                </router-link>
              </template>
              <template v-else>
                <div class="menu-header">
                  <component :is="getIconComponent(item.icon as IconName, item.iconStyle as IconStyle)"
                             v-if="item.icon"
                             class="menu-icon" />
                  <span class="menu-header-text">{{ item.title }}</span>
                </div>
              </template>
              <ul :id="`subnav-${item.title}`"
                  :class="{ 'no-dropdown': !item.isDropdown && item.isOpen }"
                  class="submenu">
                <li v-for="child in item.children"
                    :key="child.title">
                  <template v-if="child.to">
                    <router-link :data-title="child.title"
                                 :to="child.to"
                                 class="menu-item"
                                 @click="mobileLink">
                      <component :is="getIconComponent(child.icon as IconName, item.iconStyle as IconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </router-link>
                  </template>
                  <template v-else>
                    <div class="menu-item">
                      <component :is="getIconComponent(child.icon as IconName, item.iconStyle as IconStyle)"
                                 v-if="child.icon"
                                 class="menu-icon" />
                      {{ child.title }}
                    </div>
                  </template>
                </li>
              </ul>
            </template>
          </template>
          <template v-else-if="item.to">
            <router-link :data-title="item.title"
                         :to="item.to"
                         class="menu-item"
                         @click="mobileLink">
              <component :is="getIconComponent(item.icon as IconName, item.iconStyle as IconStyle)"
                         v-if="item.icon"
                         class="menu-icon" />
              {{ item.title }}
            </router-link>
          </template>
          <template v-else>
            <div class="menu-item">
              <component :is="getIconComponent(item.icon as IconName, item.iconStyle as IconStyle)"
                         v-if="item.icon"
                         class="menu-icon" />
              {{ item.title }}
            </div>
          </template>
        </li>
      </ul>

      <div class="menu menu-secondary">
        <div class="menu-footer">
          <router-link data-title="Account"
                       :to="'/' + PageNames.Account"
                       @click="mobileLink">
            <component :is="getIconComponent(IconName.UserCircleIcon as IconName, IconStyle.Solid as IconStyle)"
                       class="footer-icon" />
            {{ activeUser?.forename ?? 'Account' }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>

  <div v-if="mobileNavOpen"
       class="overlay"
       @click="toggleMobileNav" />
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

@media screen and (max-width: $breakpoint-lg) {
  .overlay {
    position: fixed;
    inset: 0;
    z-index: 12;
    background: $black-opacity-75 url("/src/assets/background.png") top left;
    transition: opacity 0.15s;
  }

  .sidebar-open {
    &.overlay {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
