<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useViewStore } from '@stores/view';
import { ButtonVariant, PageNames } from '@viewModels/enums';
import { MapMarker } from '@viewModels/mapMarker';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import Loading from '@components/Loading.vue';
import GoogleMap from '@components/maps/GoogleMap.vue';
import SubHeader from '@components/SubHeader.vue';
import ViewDirectoryFilter from '@components/view/ViewDirectoryFilter.vue';
import ViewCard from '@components/cards/ViewCard.vue';
import MapContainer from '@components/maps/MapContainer.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import EmptyState from '@layouts/EmptyState.vue';
import { Entitlements } from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';

const route = useRoute();
const router = useRouter();
const viewStore = useViewStore();
const { viewCollectionRef } = storeToRefs(viewStore);
const selectedView = ref<string | undefined>();
const sortBy = ref<string>('-lastCaptured');
const searchBy = ref<string | undefined>();
const isLoading = ref<boolean>(true);

// View Permissions
const applicationStore = useApplicationStore();
const createView = applicationStore.canUser(Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);

function onMarkerClick(_event: MouseEvent, marker: MapMarker): void {
  selectedView.value = marker.id;
  router.replace({ name: PageNames.ViewMap, params: { viewId: marker.id } });
}

watch(
  () => route.params,
  () => {
    selectedView.value = route.params['viewId'] as string;
  }
);

function onLoaded(): void {
  selectedView.value = route.params['viewId'] as string;

  if (!selectedView.value && viewStore.viewCollectionRef.data.length > 0) {
    router.replace({ name: PageNames.ViewMap, params: { viewId: viewStore.viewCollectionRef.data.at(0)?.id } });
  }
}

onMounted(async () => {
  viewStore.enablePagination(false);
  await getViewsList(searchBy.value, sortBy.value);
  onLoaded();
});

async function getViewsList(search?: string, sortBy?: string): Promise<void> {
  isLoading.value = true;
  await viewStore.obtainViewList(search, sortBy);
  isLoading.value = false;
}

async function updateSort(searchValue: string | undefined, newSortBy: string): Promise<void> {
  sortBy.value = newSortBy;
  searchBy.value = searchValue;
  await getViewsList(searchValue, newSortBy);
}
</script>

<template>
  <SubHeader heading="Map View"
             level="2" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div>
          <ViewDirectoryFilter :default-sort-by="sortBy"
                               @search="updateSort" />
          <MapContainer>
            <Directory :object-collection-reference="viewCollectionRef.data"
                       :loading="isLoading"
                       @on-loaded="onLoaded">
              <template #default="scope">
                <ViewCard :resource="scope.resource"
                          :loading="isLoading"
                          :selected="scope.resource.id === selectedView" />
              </template>
              <template #table-empty>
                <template v-if="createView">
                  <EmptyState heading-text="No views found"
                              strap-line="Get started by creating a new view"
                              :button-variant="ButtonVariant.Dark"
                              button-text="New View"
                              :icon-name="IconName.PhotoIcon"
                              :icon-style="IconStyle.Outline"
                              :to="{ name: PageNames.ViewNew }" />
                </template>
                <template v-else>
                  <EmptyState heading-text="No views found"
                              :icon-name="IconName.PhotoIcon"
                              :icon-style="IconStyle.Outline" />
                </template>
              </template>
            </Directory>

            <GoogleMap :selected-view="selectedView"
                       @on-marker-click="onMarkerClick" />
          </MapContainer>
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
