<script lang="ts" setup>
import { reactive, ref } from 'vue';

import * as client from '@gabrielcam/api-client';
import { Entitlements, GetCameraByIdStatLatestData } from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BadgeVariant, BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { DirectoryTableColumn } from '@components/directory/models/DirectoryTableModels';
import DirectoryTable from '@components/directory/DirectoryTable.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

enum TableHeaders {
  Views = 'View Name',
  Links = 'Links',
  Status = 'Change Status',
  Delete = 'Delete',
}

interface DirectoryTableType {
  reloadData: () => Promise<void>;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, active: true },
];

// Set View Permissions
const applicationStore = useApplicationStore();
const viewStore = useViewStore();

// View Permissions
const showNew = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
// const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
// const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);
const createView = applicationStore.canUser(Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);

const cameraLiveStatus = ref(new Map<string, boolean>());
const isLoading = ref<boolean>(false);

// Delete Modal
const showDeleteModal = ref<boolean>(false);
const selectedViewToDelete = ref<client.View | null>(null);

// Status Modal
const showStatusModal = ref<boolean>(false);
const selectedViewToUpdate = ref<client.View | null>(null);
const selectedViewStatus = ref<client.ViewStatus | null>(null);

const tableRef = ref<DirectoryTableType | null>(null);

const columns: DirectoryTableColumn[] = [
  {
    label: TableHeaders.Views,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    width: '98%',
  },
  {
    label: TableHeaders.Links,
    field: 'actions',
    headerClasses: 'status',
    width: '1%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'status',
    width: '1%',
  },
];

// Add the delete column if permissions allow
if (showDelete) {
  columns.push({
    label: TableHeaders.Delete,
    field: 'delete',
    headerClasses: 'status',
    width: '1%',
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

// Fetch the list of cameras and check live status
async function checkCameraLiveStatus(): Promise<client.View[]> {
  isLoading.value = true;

  try {
    // Fetch the list of views
    const views = await client.listViews({
      organisation: applicationStore.activeOrganisation!.id,
    } as client.ListViewsData);

    // Iterate over the views and check if each camera is live
    await Promise.all(
      views.data.map(async (view: client.View) => {
        try {
          // Check if the camera is live
          await client.getCameraByIdStatLatest({ cameraId: view.camera } as GetCameraByIdStatLatestData);

          // Camera is live in the map
          cameraLiveStatus.value.set(view.camera!, true);
        } catch (error) {
          console.error(`Error fetching camera stats for cameraId: ${view.camera}`, error);
          // Camera is not in the map
          cameraLiveStatus.value.set(view.camera!, false);
        }
      }),
    );

    // Return the views for the table
    return views.data;
  } catch (error) {
    console.error('Error fetching camera list:', error);
    // Return an empty array on error
    return [];
  } finally {
    isLoading.value = false;
  }
}

// View Status Badge Variant Map
const statusVariantMap = {
  [client.ViewStatus.ACTIVE]: { variant: BadgeVariant.Success, label: client.ViewStatus.ACTIVE },
  [client.ViewStatus.INACTIVE]: { variant: BadgeVariant.Danger, label: client.ViewStatus.INACTIVE },
  [client.ViewStatus.ARCHIVE]: { variant: BadgeVariant.Warning, label: client.ViewStatus.ARCHIVE },
};

function showDeleteConfirmModal(row: client.View): void {
  showDeleteModal.value = true;
  selectedViewToDelete.value = row;
}

function closeDeleteConfirmModal(): void {
  showDeleteModal.value = false;
  selectedViewToDelete.value = null;
}

async function deleteView(): Promise<void> {
  if (!selectedViewToDelete.value?.id) return;

  const result = await viewStore.deleteView(selectedViewToDelete.value.id);

  closeDeleteConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to delete view.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted view.',
    autoCloseMs: 3000,
  });

  // Reload the table on deletion
  if (tableRef.value) {
    await tableRef.value.reloadData();
  }
}

function showStatusConfirmModal(row: client.View, status: client.ViewStatus): void {
  showStatusModal.value = true;
  selectedViewToUpdate.value = row;
  selectedViewStatus.value = status;
}

function closeStatusConfirmModal(): void {
  showStatusModal.value = false;
  selectedViewToUpdate.value = null;
  selectedViewStatus.value = null;
}

async function changeViewStatus(): Promise<void> {
  const viewId = selectedViewToUpdate.value?.id;
  const viewStatus = selectedViewToUpdate.value?.status;

  if (!viewId || !viewStatus) return;

  const isDeactivation = viewStatus === client.ViewStatus.ACTIVE;
  const result = isDeactivation
    ? await viewStore.deactivateView(viewId)
    : await viewStore.activateView(viewId);

  closeStatusConfirmModal();

  if (!result) {
    applicationStore.publishErrorNotification({ text: 'Failed to change view status.' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully changed view status.',
    autoCloseMs: 3000,
  });

  // Reload the table
  await tableRef.value?.reloadData();
}

</script>

<template>
  <SubHeader heading="Views"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="createView"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewNew }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusIcon"
                       :icon-style="IconStyle.Solid">
        Add View
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <DirectoryTable ref="tableRef"
                          :columns="columns"
                          :retrieve-data="checkCameraLiveStatus"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No views found"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline">
                <template v-if="showNew">
                  <p>Get started by registering your first view</p>
                  <ButtonComponent :to="{ name: PageNames.ViewNew }"
                                   :variant="ButtonVariant.Dark">
                    Add View
                  </ButtonComponent>
                </template>
              </EmptyState>
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Views">
                <div class="d-flex align-center gap-10">
                  <span>{{ row.name }}</span>
                  <BadgeComponent v-if="statusVariantMap[row.status as client.ViewStatus]"
                                  :is-pill="true"
                                  :variant="statusVariantMap[row.status as client.ViewStatus].variant">
                    {{ statusVariantMap[row.status as client.ViewStatus].label }}
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Links">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="row.id"
                                   :icon-name="IconName.AdjustmentsHorizontalIcon"
                                   :icon-style="IconStyle.Solid"
                                   :to="{ name: PageNames.ViewInformation, params: { id: row.id } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Info
                  </ButtonComponent>
                  <ButtonComponent v-if="row.camera && cameraLiveStatus.get(row.camera)"
                                   :icon-name="IconName.CameraIcon"
                                   :icon-style="IconStyle.Solid"
                                   :to="{ name: PageNames.CameraStatus, params: { id: row.camera } }"
                                   :variant="ButtonVariant.Dark"
                                   :size="ButtonSize.Small">
                    Camera
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Status">
                <div class="d-flex justify-center">
                  <ButtonComponent v-if="row.status === client.ViewStatus.ACTIVE"
                                   :variant="ButtonVariant.Warning"
                                   :size="ButtonSize.Small"
                                   @click="showStatusConfirmModal(row, client.ViewStatus.INACTIVE)">
                    Deactivate
                  </ButtonComponent>
                  <ButtonComponent v-if="row.status === client.ViewStatus.INACTIVE || row.status === client.ViewStatus.ARCHIVE"
                                   :icon-name="IconName.RocketLaunchIcon"
                                   :icon-style="IconStyle.Solid"
                                   :variant="ButtonVariant.Success"
                                   :size="ButtonSize.Small"
                                   @click="showStatusConfirmModal(row, client.ViewStatus.ACTIVE)">
                    Activate
                  </ButtonComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Delete">
                <div class="d-flex gap-20">
                  <ButtonComponent v-if="showDelete"
                                   :icon-name="IconName.TrashIcon"
                                   :icon-style="IconStyle.Solid"
                                   :variant="ButtonVariant.Danger"
                                   :size="ButtonSize.Small"
                                   @click="showDeleteConfirmModal(row)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </DirectoryTable>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete View Modal -->
  <ModalComponent v-if="showDelete && selectedViewToDelete"
                  :visible="true"
                  heading-title="Delete View"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the view: <span class="text--bold">{{ selectedViewToDelete.name }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteView()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>


  <!-- Status View Modal -->
  <ModalComponent v-if="showStatusModal && selectedViewToUpdate"
                  :visible="true"
                  heading-title="Change View Status"
                  @on-close="closeStatusConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to
        <span class="text--bold">{{ selectedViewToUpdate.status === client.ViewStatus.ACTIVE ? 'deactivate' : 'activate' }}</span> the view:
        <span class="text--bold">{{ selectedViewToUpdate.name }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeStatusConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Success"
                       @click="changeViewStatus()">
        Confirm
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

:deep(.table-wrapper .btn--small ) {
  min-width: 120px;
}
</style>
