<script lang="ts" setup>
import { computed, onMounted, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { Entitlements, View } from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useMobileDetection } from '@utils/isMobile';
import { AlertVariant, BreadcrumbPaths, ButtonType, ButtonVariant, ImageSize, PageNames } from '@viewModels/enums';
import { Resolution } from '@viewModels/resolutionViewModel';
import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import ResolutionDropDown from './ResolutionDropDown.vue';
import AlertBanner from '@components/AlertBanner.vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import Loading from '@components/Loading.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Forbidden from '@layouts/Forbidden.vue';
import ModalComponent from '@components/ModalComponent.vue';
import LoadingBar from '@components/LoadingBar.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';

dayjs.extend(isSameOrAfter);

/**
 * This outlines the step-by-step process for this form.
 *
 * 1a. Select a View - Fetch a view or multiple views for the active organisation using initialiseViews() this is fired onMounted()
 * 1b. View Selected - viewSelected() is called from initialiseViews() in step 1a. It gets the data for the Date Picker on the next step
 * 2. Select a Date Range - Fetch available images on a view using onUpdateDateRange() and getImages() between a given date range, AND time range.
 * Initially we fetch all images from 00:00 to 23:59 for the selected view at 1b. viewSelected(), but if a user advances to the next step (3.) and updates a time range, we must then use the new time range.
 * 3. Select a Time Range - Fetch images between a given time range for a selected date range using handleTimeRangeChange() and getImages().
 *
 *
 * Points to note:
 * We don't pass a props.viewId, but you can if you want to use this form on the view route
 */

// Props
const props = defineProps<{
  viewId?: string;
}>();

// New Video Interface
interface NewVideoForm {
  view: string;
  resolution: Resolution;
  frameRate: number;
  duration: number;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
}

// Page Address Interface
interface PageAddress {
  day?: number;
  week?: number;
  month: number;
  year: number;
}

// Date Picker Interface
interface HighlightedDates {
  key: string;
  highlight?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink' | {
    color?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
    fillMode?: 'solid' | 'light' | 'outline';
  };
  dot?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
  order?: number;
  dates: string[];
}

// Image Interface
interface ImageDetails {
  originalFileName: string;
  sourceURL: string;
  capturedAt: string;
}

// YUP Form Validation
const { handleSubmit, isSubmitting, validateField } = useForm<NewVideoForm>({
  validationSchema: yup.object({
    view: yup
      .string()
      .required('View is required'),
    dateRange: yup
      .object({
        start: yup
          .string()
          .nullable()
          .typeError('Start date is required')
          .required('Start date is required'),
        end: yup
          .string()
          .nullable()
          .typeError('End date is required')
          .required('End date is required')
          .test('is-valid-date', 'End date must be the same or after the start date', function (value) {
            const { start } = this.parent;
            if (!start || !value) return true; // If either value is null, don't validate further
            return dayjs(value).isSameOrAfter(dayjs(start)); // Allow same-day range
          }),
      })
      .required('Date range is required'),
    frameRate: yup
      .number()
      .integer('Frame Rate must be a whole number')
      .min(5, 'Frame Rate must be at least 5')
      .max(60, 'Frame Rate must be no more than 60')
      .typeError('Frame Rate must be a number')
      .required('Frame Rate is required'),
    duration: yup
      .number()
      .min(5, 'Duration must be at least 5')
      .max(300, 'Duration must be no more than 300')
      .typeError('Duration must be a number')
      .required('Duration is required'),
    startTime: yup
      .string()
      .matches(/^(?:[01]\d|2[0-3]):[0-5]\d$/, 'Invalid Format - Format must be HH:MM')
      .required('Start Time is required'),
    endTime: yup
      .string()
      .matches(/^(?:[01]\d|2[0-3]):[0-5]\d$/, 'Invalid Format - Format must be HH:MM')
      .required('End Time is required')
      .test('is-after-start', 'End time must be after start time.', function(value) {
        const { startTime } = this.parent;
        if (!startTime || !value) return true;
        return dayjs(`2024-01-01T${value}`).isAfter(dayjs(`2024-01-01T${startTime}`));
      }),
  }),
});

// Form Fields
const { value: viewValue, errorMessage: viewValueError } = useField<string>('view');
const { value: resolutionValue, errorMessage: resolutionError } = useField<Resolution>('resolution');
const { value: frameRateValue, errorMessage: frameRateError } = useField<number>('frameRate');
const { value: durationValue, errorMessage: durationError } = useField<number>('duration');
const { value: dateRange, errorMessage: dateRangeError } = useField<{ start: string; end: string }>('dateRange');
const { value: startTimeValue, errorMessage: startTimeError, meta: startTimeMeta } = useField<string>('startTime');
const { value: endTimeValue, errorMessage: endTimeError, meta: endTimeMeta } = useField<string>('endTime');

// Form Default Values
startTimeValue.value = '00:00';
endTimeValue.value = '23:59';
frameRateValue.value = 25;
durationValue.value = 30;

// Constants
const router = useRouter();
const applicationStore = useApplicationStore();
const { isMobile } = useMobileDetection();
const calendar = ref<InstanceType<typeof DatePicker>>();

// Permissions
const showNew = applicationStore.canUser(Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);

// Sections
const currentSectionNumber = ref<number>(0);
const firstSectionNumber = ref<number>(0);
const lastSectionNumber = ref<number>(3);
const previousSectionNumber = ref<number>(0);

// Loaders and Flags
const isLoading = ref<boolean>(true);
const noViewsFound = ref<boolean>(false);
const isGettingAvailableDates = ref<boolean>(true);
const isGettingImages = ref<boolean>(false);
const isImagesInitialised = ref(false);

// Image Modal
const isImageModalVisible = ref<boolean>(false);
const selectedModalImage = ref<string | undefined>(undefined);
const isLoadingImageModal = ref<boolean>(true);
const selectedModalCapturedAt = ref<string | undefined>(undefined);

// Data
const availableDates = ref<string[]>([]);
const allImages = ref<ImageDetails[]>([]);
const availableImageCount = ref<number>(0);
const filteredImages = ref<string[]>([]);
const filteredImageCount = ref<number>(0);
const filteredViews = ref<client.View[]>([]);
const imagesCapturedOn: Ref<HighlightedDates[]> = ref([]);

// Image src and dates
const firstImageSource = ref<string | null>(null);
const lastImageSource = ref<string | null>(null);
const firstCapturedAt = ref<string | null>(null);
const lastCapturedAt = ref<string | null>(null);

// Create Button Validation
const createButtonDisabled = computed(() => {
  return !viewValue.value ||
    !dateRange.value ||
    !dateRange.value.start ||
    !dateRange.value.end ||
    !startTimeValue.value ||
    !endTimeValue.value ||
    !frameRateValue.value ||
    !durationValue.value ||
    !resolutionValue.value ||
    getAllImageCount.value === 0;
});


/**
 * Step 1a: Select a View | initialiseViews()
 * Initialises the views by fetching them from the client organisation or by a single viewId prop.
 *
 * @async
 * @returns {Promise<void>}
 */
const initialiseViews = async (): Promise<void> => {
  // Set loading state and reset noViewsFound flag
  isLoading.value = true;
  noViewsFound.value = false;

  try {
    let views: client.View[];

    if (props.viewId) {
      // Fetch a single view by ID if a viewId prop is passed
      const view = await client.getViewById({ viewId: props.viewId });
      views = view.imageCount >= 1 ? [view] : [];
    } else {
      // Fetch all views for the active organisation if no prop is passed
      const response = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
      views = response.data;
    }

    // Filter and sort views
    filteredViews.value = views
      .filter((view) => view.imageCount >= 1)
      .sort((a, b) => a.name.localeCompare(b.name));

    // If exactly one view is found, set it as the selected view
    if (filteredViews.value.length === 1) {
      const view = filteredViews.value[0];
      if (view) {
        viewValue.value = view.id;
        await viewSelected(view);
      }
    }

    // Flag no views found if none are available
    noViewsFound.value = filteredViews.value.length === 0;
  } catch (error) {
    console.error('Failed to initialise views:', error);
    noViewsFound.value = true;
  } finally {
    isLoading.value = false;
  }
};

// Set up the initial view selection
onMounted(() => {
  initialiseViews();
});


/**
 * Step 1b: View Selected | viewSelected()
 * Fetches the available capture dates and images for a given view.
 * This could be a potential issue with large views as we are requesting a lot of data. This could be refactored to just select a smaller window, but plot the available dates for the user
 * This is used when the user selects a view from the dropdown
 * @param {View} view - The view object.
 * @returns {Promise<void>} - A promise that resolves when the function completes.
 */
async function viewSelected(view: View): Promise<void> {
  isGettingAvailableDates.value = true;
  isGettingImages.value = true;

  currentSectionNumber.value = 0;
  availableImageCount.value = 0;

  try {
    const capturedDates = await client.listViewByIdCapturedDates({ viewId: view.id });
    availableDates.value = capturedDates.data;

    // console.log('Captured Dates (Raw):', capturedDates.data);

    // If we have available dates, update the imagesCapturedOn array and set the theme // https://vcalendar.io/calendar/attributes.html
    if (availableDates.value.length > 0) {
      const validDates = availableDates.value.filter((date) => dayjs(date).isValid());
      imagesCapturedOn.value = [
        {
          key: 'availableDates',
          highlight: {
            color: 'green',
            fillMode: 'light',
          },
          order: -1,
          dates: validDates.map((date) => dayjs(date).format('YYYY-MM-DD')),
        }
      ];

      // Get the pre-selected dates from the validDates and format as ISO 8601 with timezone for the DatePicker getDefaultDateRange() and getImages()
      const lastAvailableDate = dayjs.utc(validDates[validDates.length - 1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const firstAvailableDate = dayjs(lastAvailableDate).startOf('month').format('YYYY-MM-DDTHH:mm:ss[Z]');

      // Set the Date Picker default date range using the last available date, getDefaultDateRange() will set the start date to the first day of the month of the lastAvailableDate
      const defaultDateRange = getDefaultDateRange(lastAvailableDate);

      // getImages() for the pre-selected default date range
      allImages.value = await getImages(view.id, firstAvailableDate, lastAvailableDate);

      availableImageCount.value = allImages.value.length;

      // console.group('Default Date Range Debug');
      // console.log('Default Start (raw):', defaultDateRange.start);
      // console.log('Default End (raw):', defaultDateRange.end);
      // console.groupEnd();

      // Convert the default date range to strings
      dateRange.value = {
        start: defaultDateRange.start,
        end: defaultDateRange.end,
      };

      // console.group('Converted Date Range');
      // console.log('Converted Start (Date object):', dateRange.value.start);
      // console.log('Converted End (Date object):', dateRange.value.end);
      // console.groupEnd();

      // Filter allImages based on the default date range ISO 8601 UTC format
      const filteredImages = allImages.value.filter((image) => {
        const capturedAt = dayjs.utc(image.capturedAt).format('YYYY-MM-DDTHH:mm:ss[Z]');
        return (
          capturedAt >= defaultDateRange.start &&
          capturedAt <= defaultDateRange.end
        );
      });

      // Sort the filtered images by `capturedAt` in ascending order
      const sortedFilteredImages = filteredImages.sort((a, b) =>
        dayjs(a.capturedAt).isBefore(dayjs(b.capturedAt)) ? -1 : 1
      );

      // console.group('Filtered Images:');
      // console.log('Filtered Images Before Sorting:', filteredImages.map((img) => img.capturedAt));
      // console.log('Filtered Images After Sorting:', sortedFilteredImages.map((img) => img.capturedAt));
      // console.log('First Image in Array:', sortedFilteredImages[0]?.capturedAt);
      // console.log('Last Image in Array:', sortedFilteredImages[sortedFilteredImages.length - 1]?.capturedAt);
      // console.groupEnd();

      // Update the filtered image count
      filteredImageCount.value = sortedFilteredImages.length;

      if (sortedFilteredImages.length > 0) {
        // Use sorted images to ensure correct order
        firstImageSource.value = sortedFilteredImages[0]?.sourceURL || '';
        lastImageSource.value = sortedFilteredImages[sortedFilteredImages.length - 1]?.sourceURL || '';
        firstCapturedAt.value = sortedFilteredImages[0]?.capturedAt || '';
        lastCapturedAt.value = sortedFilteredImages[sortedFilteredImages.length - 1]?.capturedAt || '';
      } else {
        firstImageSource.value = null;
        lastImageSource.value = null;
        firstCapturedAt.value = null;
        lastCapturedAt.value = null;
      }

    } else {
      imagesCapturedOn.value = [];

      // Use getDefaultDateRange with today's date
      const today = dayjs().format('YYYY-MM-DD'); // Format today's date as a string
      const defaultDateRange = getDefaultDateRange(today);
      
      // Convert the default date range to strings
      dateRange.value = {
        start: defaultDateRange.start,
        end: defaultDateRange.end,
      };

      allImages.value = [];
      filteredImageCount.value = 0;
      firstImageSource.value = null;
      lastImageSource.value = null;
    }
  } catch (error) {
    console.error('Error fetching dates or images:', error);
    applicationStore.publishErrorNotification({ text: 'Error fetching dates or images' });
    allImages.value = [];
    filteredImageCount.value = 0;
    firstImageSource.value = null;
    lastImageSource.value = null;
  } finally {
    isGettingAvailableDates.value = false;
    isGettingImages.value = false;
  }
}


/**
 * Step 2: Update Date Range | onUpdateDateRange(range: { start: string; end: string })
 * Handles the update of the date range selection and the subsequent time range filtering.
 * Initially fetches all images between the full date range (00:00 - 23:59) and then filters the images based on the selected time range.
 *
 * @param {Object} range - The selected date range with start and end dates.
 * @param {string} range.start - The start date of the selected range.
 * @param {string} range.end - The end date of the selected range.
 *
 * @returns {Promise<void>} A promise that resolves when the function completes.
 */
const onUpdateDateRange = async (range: { start: string; end: string }): Promise<void> => {
  isGettingAvailableDates.value = true;
  isGettingImages.value = true;

  try {
    // console.log('Raw Range:', range);

    if (!range.start || !range.end) {
      console.error('Invalid date range provided:', range);
      dateRange.value = { start: '', end: '' };
      filteredImageCount.value = 0;
      return;
    }

    // Update dateRange start and end
    dateRange.value = {
      start: range.start,
      end: range.end,
    };

    // Format the start and end dates for the normalised range
    const startDay = dayjs.utc(range.start).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    const endDay = dayjs.utc(range.end).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');

    // Fetch available images for the normalised range
    const fetchedImages = await getImages(viewValue.value, startDay, endDay);

    // console.group('Fetched Images');
    // console.log('Total Images Count:', fetchedImages.length);
    // console.log('Images:', fetchedImages.map((img) => img.capturedAt));
    // console.groupEnd();

    // Parse selected time range; default to 00:00 - 23:59
    const startTime = startTimeValue.value || '00:00';
    const endTime = endTimeValue.value || '23:59';

    // Filter images based on normalised range and selected time
    const filteredImages = fetchedImages.filter((image) => {
      const capturedAt = dayjs.utc(image.capturedAt); // Parse UTC
      const imageDate = capturedAt.format('YYYY-MM-DD');
      const imageTime = capturedAt.format('HH:mm');

      const isWithinDateRange =
        imageDate >= dayjs.utc(range.start).format('YYYY-MM-DD') &&
        imageDate <= dayjs.utc(range.end).format('YYYY-MM-DD');
      const isWithinTimeRange = imageTime >= startTime && imageTime <= endTime;

      return isWithinDateRange && isWithinTimeRange;
    });

    // console.group('Filtered Images');
    // console.log('Filtered Images Count:', filteredImages.length);
    // console.log('Filtered Images:', filteredImages.map((img) => img.capturedAt));
    // console.groupEnd();

    // Sort filtered images
    const sortedFilteredImages = filteredImages.sort((a, b) =>
      dayjs.utc(a.capturedAt).isBefore(dayjs.utc(b.capturedAt)) ? -1 : 1
    );

    filteredImageCount.value = sortedFilteredImages.length;

    if (sortedFilteredImages.length > 0) {
      firstImageSource.value = sortedFilteredImages[0]?.sourceURL || null;
      lastImageSource.value = sortedFilteredImages[sortedFilteredImages.length - 1]?.sourceURL || null;
      firstCapturedAt.value = sortedFilteredImages[0]?.capturedAt || null;
      lastCapturedAt.value = sortedFilteredImages[sortedFilteredImages.length - 1]?.capturedAt || null;

      // console.group('Image Details');
      // console.log('First Captured At:', firstCapturedAt.value);
      // console.log('Last Captured At:', lastCapturedAt.value);
      // console.groupEnd();

    } else {
      console.warn('No images found for the selected range.');
      firstImageSource.value = null;
      lastImageSource.value = null;
      firstCapturedAt.value = null;
      lastCapturedAt.value = null;
    }
  } catch (error) {
    console.error('Error updating date range:', error);
    allImages.value = [];
    filteredImageCount.value = 0;
    firstImageSource.value = null;
    lastImageSource.value = null;
    firstCapturedAt.value = null;
    lastCapturedAt.value = null;
  } finally {
    isGettingAvailableDates.value = false;
    isGettingImages.value = false;
  }
};



/**
 * Handles the time range change event.
 * Debounce the function to prevent excessive API calls.
 */
const handleTimeRangeChange = useDebounceFn(async () => {
  // Validate the `startTime` and `endTime` fields for validity
  if (!(await validateField('startTime')) || !(await validateField('endTime'))) {
    console.error('Validation failed for startTime or endTime. Aborting handleTimeRangeChange.');
    return;
  }

  // console.group('Checking Time Range');
  // console.info('Start Date Range:', dateRange.value.start);
  // console.info('End Date Range:', dateRange.value.end);
  // console.info('Start Time:', startTimeValue.value);
  // console.info('End Time:', endTimeValue.value);
  // console.groupEnd();

  // Abort if any of the required inputs are missing
  if (!viewValue.value || !dateRange.value.start || !dateRange.value.end) {
    console.error('Invalid inputs for time range change.');
    return;
  }

  // Parse start and end time values
  const [startHour, startMinute] = startTimeValue.value.split(':').map(Number);
  const [endHour, endMinute] = endTimeValue.value.split(':').map(Number);

  // Generate valid date-time ranges within the date range
  const validDateTimeRanges: { start: Date; end: Date }[] = [];
  for (
    let current = new Date(dateRange.value.start);
    current <= new Date(dateRange.value.end);
    current.setUTCDate(current.getUTCDate() + 1)
  ) {
    const dayStart = new Date(Date.UTC(
      current.getUTCFullYear(),
      current.getUTCMonth(),
      current.getUTCDate(),
      startHour,
      startMinute,
      0,
    ));
    const dayEnd = new Date(Date.UTC(
      current.getUTCFullYear(),
      current.getUTCMonth(),
      current.getUTCDate(),
      endHour,
      endMinute,
      0,
    ));

    if (dayEnd > dayStart) {
      validDateTimeRanges.push({ start: dayStart, end: dayEnd });
    }
  }

  // console.info('Generated Valid Date-Time Ranges:', validDateTimeRanges);

  // Validate that at least one valid date-time range exists
  if (validDateTimeRanges.length === 0) {
    console.error('No valid date-time ranges generated. Aborting handleTimeRangeChange.');
    return;
  }

  isGettingImages.value = true;

  try {
    // console.group('getImages Request');
    // console.log('View ID:', viewValue.value);
    // console.log('Start DateTime (ISO):', dayjs.utc(dateRange.value.start).startOf('day').toISOString());
    // console.log('End DateTime (ISO):', dayjs.utc(dateRange.value.end).endOf('day').toISOString());
    // console.groupEnd();

    // Fetch all images for the range
    const images = await getImages(
      viewValue.value,
      dayjs.utc(dateRange.value.start).startOf('day').toISOString(),
      dayjs.utc(dateRange.value.end).endOf('day').toISOString()
    );

    // Filter images to match the valid date-time ranges
    const filteredImages = images.filter((image) => {
      const capturedAt = new Date(image.capturedAt);
      return validDateTimeRanges.some(
        (range) => capturedAt >= range.start && capturedAt <= range.end
      );
    });

    // Update the fetched and filtered images
    allImages.value = filteredImages;
    filteredImageCount.value = filteredImages.length;

  } catch (error) {
    console.error('Error fetching images for Time Range Change:', error);
    allImages.value = [];
    filteredImageCount.value = 0;
  } finally {
    isGettingImages.value = false;
  }
}, 500);






/**
 * Handles the form submission for creating a new video sequence.
 *
 * @param {object} values - The form values submitted by the user.
 */
const onSubmit = handleSubmit(async (values) => {
  // console.log('Form Submitted:', values);

  // Check we are on currentSectionNumber 3
  if (currentSectionNumber.value !== 3) {
    console.error('Not on currentSectionNumber 3 Section');
    return;
  }

  // Check if the active organisation is set before proceeding.
  if (!applicationStore.activeOrganisation) {
    console.error('Active Organisation is not set.');
    return;
  }

  // Use `dateRange` for extracting `start` and `end` dates
  const { start, end } = dateRange.value;

  if (!start || !end) {
    console.error('Date range is incomplete.');
    return;
  }

  // Combine the date and time values into dayjs objects and format as strings
  const startDateTime = dayjs(`${dayjs(start).format('YYYY-MM-DD')}T${startTimeValue.value}`);
  const endDateTime = dayjs(`${dayjs(end).format('YYYY-MM-DD')}T${endTimeValue.value}`);


  // Ensure startDateTime is before endDateTime
  if (!startDateTime.isBefore(endDateTime)) {
    console.error('Start time must be earlier than end time.');
    return;
  }

  // Fetch the images for the specified view and date range.
  const images = await getImages(
    values.view,
    startDateTime.format('YYYY-MM-DDTHH:mm:ss'),
    endDateTime.format('YYYY-MM-DDTHH:mm:ss'));

  // Filter the images based on the current filter criteria and reverse the order so the video is created in the right order.
  filteredImages.value = filterImages(images.map((image) => image.originalFileName)).reverse();

  // Find the view object that matches the selected view ID so we can pass view name.
  const view = filteredViews.value.find((x: client.View) => x.id == values.view);

  // Checking Form Validation can be removed
  // if (start || end) {
  //   console.group('Form Validation');
  //   console.info('Start Time:', startTimeValue.value);
  //   console.info('End Time:', endTimeValue.value);
  //   console.info('Start Date:', dayjs(start).format('YYYY-MM-DD'));
  //   console.info('End Date:', dayjs(end).format('YYYY-MM-DD'));
  //   console.info('Start DateTime:', startDateTime.format('YYYY-MM-DDTHH:mm:ss'));
  //   console.info('End DateTime:', endDateTime.format('YYYY-MM-DDTHH:mm:ss'));
  //   console.info('Filtered Images:', filteredImages);
  //   console.info('View:', view?.name);
  //   console.groupEnd();
  //   return;
  // }

  // Create the request body for the create sequence API call.
  // Use name as the captured from and to times to display on the VideoCard Component
  const requestBody: client.CreateSequenceRequest = {
    view: values.view,
    name: `${view?.name}`,
    organisation: applicationStore.activeOrganisation.id,
    options: {
      bitrate: values.resolution.bitrate,
      blend: 'average',
      crop: true,
      framerate: values.frameRate,
      height: values.resolution.height,
      width: values.resolution.width,
      startDate: `${startDateTime}`,
      endDate: `${endDateTime}`,
      startTime: startTimeValue.value,
      endTime: endTimeValue.value,
    },
    images: filteredImages.value,
  };

  // console.info('requestBody', requestBody);

  // Check if there are any images in the request body.
  if (requestBody.images.length === 0) {
    applicationStore.publishErrorNotification({ text: 'There are no images within this period. Please select a different date range.' });
    return;
  }

  try {
    await client.createSequence({ requestBody });

    applicationStore.publishSuccessNotification({
      text: 'Successfully queued the video creation.',
      autoCloseMs: 3000,
    });

    await router.push({ name: PageNames.Videos, params: { viewId: props.viewId } });

  } catch (error) {
    console.error('Error creating video:', error);
    applicationStore.publishErrorNotification({
      text: 'Error creating video',
    });
  }
});



/**
 * Filters a list of images based on the start and end time, and limits the number of images based on the duration and frame rate.
 *
 * @param {string[]} images - The list of images to filter.
 * @returns {string[]} The filtered list of images.
 */
function filterImages(images: string[]): string[] {
  // console.warn('Filtering images:', images);
  // console.group('Filtering Images');
  // console.info('Number of images before filtering:', images.length);

  const startTime = startTimeValue.value.split(':');
  const startHour = Number(startTime[0]);
  const startMinute = Number(startTime[1]);
  const endTime = endTimeValue.value.split(':');
  const endHour = Number(endTime[0]);
  const endMinute = Number(endTime[1]);

  let selectedImages = images.filter((image) => {
    const splitFileName = image.split('_');
    if (splitFileName.length !== 2) return false;

    const imageTime = splitFileName[1]!.split('-');
    if (imageTime.length !== 3) return false;

    const imageHour = Number(imageTime[0]);
    const imageMinute = Number(imageTime[1]);

    if (imageHour >= startHour && imageHour <= endHour) {
      if (imageHour === startHour) {
        return imageMinute >= startMinute;
      }
      if (imageHour === endHour) {
        return imageMinute <= endMinute;
      }
      return true;
    }
    return false;
  });

  // console.info('Number of images after time filtering:', selectedImages.length);

  if (durationValue.value !== 0) {
    const maxNumberImages = frameRateValue.value * durationValue.value;
    const currentNumberOfImages = selectedImages.length;
    const delta = Math.floor(currentNumberOfImages / maxNumberImages);

    // console.info('Max number of images allowed:', maxNumberImages);
    // console.info('Current number of images:', currentNumberOfImages);

    if (currentNumberOfImages > maxNumberImages) {
      selectedImages = selectedImages.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      const imagesLimited: string[] = [];
      for (let i = 0; i < selectedImages.length; i = i + delta) {
        imagesLimited.push(selectedImages[i]!);
      }
      // console.info('Number of images after duration filtering:', imagesLimited.length);
      selectedImages = imagesLimited;
    }
  }

  // console.info('Total Filtered Images:', selectedImages.length);
  // console.groupEnd();
  return selectedImages;
}

/**
 * Calculates the default date range for a given end date.
 *
 * @param {string} endDate - The end date of the range in ISO 8601 format.
 * @returns {{ start: string; end: string }} - The start and end of the range in ISO 8601 format.
 */
function getDefaultDateRange(endDate: string): { start: string; end: string } {
  const startDate = dayjs.utc(endDate).format('YYYY-MM-01'); // First day of the month

  return {
    start: startDate,
    end: endDate,
  };
}

// Watch Functions
/**
 * Watches the currentSectionNumber and is triggered when the currentSectionNumber changes.
 * We use this to update the UI using previousSectionNumber and currentSectionNumber
 *
 * @param {number} newSection - The new value of currentSectionNumber.
 * @param {number} oldSection - The previous value of currentSectionNumber.
 */
watch(currentSectionNumber, async (newSection, oldSection) => {
  previousSectionNumber.value = oldSection;
  currentSectionNumber.value = newSection;
  // console.group('Changed Sections Using Prev/Next Btn:');
  // console.log('Current Section Number:', currentSectionNumber.value);
  // console.log('Previous Section Number:', previousSectionNumber.value);
  // console.groupEnd();
});


// Functions
/**
 * Retrieves a list of images for a specific view within a given date range. This is used in multiple functions.
 *
 * @param viewId - The ID of the view to retrieve images for.
 * @param startDate - The start date of the range (inclusive).
 * @param endDate - The end date of the range (inclusive).
 * @returns A promise that resolves to an array of image objects.
 */
async function getImages(viewId: string, startDate: string, endDate: string): Promise<ImageDetails[]> {
  try {
    isSubmitting.value = true;

    // console.group('Get Images');
    // console.info('View ID:', viewId);
    // console.info('Start Date:', startDate);
    // console.info('End Date:', endDate);
    // console.groupEnd();

    // Fetch images from the client API
    const response = await client.listViewByIdImages({
      viewId,
      startDate,
      endDate,
      status: client.ImageStatus.COMPLETE,
    });

    // Transform and return the image data
    return response.data.map((image: client.Image) => ({
      originalFileName: image.originalFileName ?? 'Unknown File Name',
      sourceURL: image.sourceURL ?? 'Unknown Source URL',
      capturedAt: image.capturedAt ?? 'Unknown Captured At',
    }));
  } catch (error) {
    console.error('Error fetching images:', error);
    applicationStore.publishErrorNotification({ text: 'Error fetching images' });
    // Return an empty array if an error occurs
    return [];
  } finally {
    isSubmitting.value = false;
  }
}

/**
 * Resets the time range to its default values.
 *
 * This function sets the start time to '00:00' and the end time to '23:59',
 * and then triggers the handleTimeRangeChange function to update the UI.
 */
const resetTimeRange = (): void => {
  startTimeValue.value = '00:00';
  endTimeValue.value = '23:59';
  handleTimeRangeChange();
};

const formatNumber = (count: number): string => {
  return new Intl.NumberFormat().format(count);
};

/**
 * Cancels the current action and navigates to the appropriate route based on the previous route.
 * If the previous route is the login page with a continueUrl query parameter, navigates to the videos page.
 * If there is a valid previous route, goes back to it.
 * Otherwise, navigates to the views page.
 */
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /videos
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Videos as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};


// Computed Functions
const initialPage = computed((): PageAddress | null => {
  if (availableDates.value.length > 0) {
    // Get the last available date
    const lastDate = dayjs.utc(availableDates.value[availableDates.value.length - 1]);

    // Return the PageAddress object
    return {
      month: lastDate.month() + 1, // Month (1-based, so add 1)
      year: lastDate.year(),
    };
  } else {
    return null;
  }
});
/**
 * Computed property to determine if the "Previous" button should be disabled.
 *
 * This property checks for the following conditions:
 * 1. No images are available.
 * 2. No filtered images are available and the current section number is 1 or more.
 * 3. The start or end time is invalid.
 *
 * @returns {boolean} True if the "Previous" button should be disabled, false otherwise.
 */
const isPreviousBtnDisabled = computed(() => {
  const noImages = !allImages.value.length;
  const noFilteredImagesInSection = !filteredImageCount.value && currentSectionNumber.value >= 1;
  const invalidTimes = !startTimeMeta.valid || !endTimeMeta.valid;

  return noImages || noFilteredImagesInSection || invalidTimes;
});

/**
 * Computed property to determine if the "Next" button should be disabled.
 *
 * This property checks for the following conditions:
 * 1. If any form fields have errors.
 * 2. If the view field is empty.
 * 3. If the date range is incomplete (i.e., start or end date is missing).
 * 4. If the total image count or filtered image count is 0.
 * 5. If images are currently being fetched.
 *
 * @returns {boolean} True if the "Next" button should be disabled, false otherwise.
 */
const isNextBtnDisabled = computed(() => {
  const isFormInvalid = !!(
    viewValueError.value ||
    dateRangeError.value ||
    resolutionError.value ||
    frameRateError.value ||
    durationError.value ||
    startTimeError.value ||
    endTimeError.value
  );
  const isViewEmpty = !viewValue.value;

  const isDateRangeIncomplete = !dateRange.value || !dateRange.value.start || !dateRange.value.end;

  const isImageCountZero = getAllImageCount.value === 0;

  const isFilteredImageCountZero = filteredImageCount.value === 0;

  const isLoading = isGettingImages.value;

  return (
    isImageCountZero ||
    isFilteredImageCountZero ||
    isLoading ||
    isFormInvalid ||
    isViewEmpty ||
    isDateRangeIncomplete
  );
});


/**
 * Computed property to format the start date of the selected date range.
 *
 * Returns a string representing the start date in the format 'dddd, MMMM D, YYYY'
 * if a start date is selected, otherwise returns 'Start date not selected'.
 */
const formattedStartDate = computed(() => {
  if (dateRange.value.start) {
    // Format the start date using dayjs in UTC
    return `${dayjs.utc(dateRange.value.start).format('dddd, MMMM DD, YYYY')}`;
  } else {
    return 'Start date not selected';
  }
});

/**
 * Computed property to format the end date of the selected date range.
 *
 * Returns a string representing the end date in the format 'dddd, MMMM D, YYYY'
 * if an end date is selected, otherwise returns 'End date not selected'.
 */
const formattedEndDate = computed(() => {
  if (dateRange.value.end) {
    // Format the end date using dayjs in UTC
    return `${dayjs.utc(dateRange.value.end).format('dddd, MMMM DD, YYYY')}`;
  } else {
    return 'End date not selected';
  }
});


/**
 * Computed property to get the name of the currently selected view.
 *
 * Returns the name of the selected view if found, otherwise returns null.
 */
const selectedViewName = computed(() => {
  // Find the selected view from the filtered views based on the current view value
  const selectedView = filteredViews.value.find((view) => view.id === viewValue.value);
  // Return the name of the selected view if found, otherwise return null
  return selectedView?.name || null;
});

/**
 * Computed property to determine the minimum available date.
 *
 * Returns the first available date as a Date object if availableDates is not empty, otherwise returns null.
 */
const minDate = computed(() => {
  if (availableDates.value.length > 0) {
    // Return the first available date as a local Date object
    return dayjs.utc(availableDates.value[0]).toDate(); // Use UTC explicitly
  } else {
    return null;
  }
});

/**
 * Computed property to determine the maximum available date.
 *
 * Returns the last available date as a Date object if availableDates is not empty, otherwise returns null.
 */
const maxDate = computed(() => {
  if (availableDates.value.length > 0) {
    // Convert the last available date to a UTC Date object
    return dayjs.utc(availableDates.value[availableDates.value.length - 1]).toDate();
  } else {
    return null;
  }
});

/**
 * Computed property to get the total count of all images.
 *
 * Returns the length of the allImages array if images are not being fetched, otherwise returns null.
 */
const getAllImageCount = computed(() => {
  if (isGettingImages.value) {
    return null; // Still loading
  }
  return allImages.value.length;
});

/**
 * Opens the image modal with the provided image source and captured at date.
 *
 * @param {string} image - The source URL of the image to display in the modal.
 * @param {string} capturedAt - The date the image was captured.
 */
const openImageModal = (image: typeof selectedModalImage.value, capturedAt: string): void => {
  if (!image) {
    // Log an error and close the modal if no image source was provided
    console.error('No image source provided to openImageModal.');
    closeImageModal()
    return;
  }

  // Update the modal image and captured at date
  selectedModalImage.value = image;
  selectedModalCapturedAt.value = capturedAt;

  // Open the modal
  isLoadingImageModal.value = true;
  isImageModalVisible.value = true;

  // Create a new image element to load the image
  const img = new Image();
  // Set the source URL of the image depending on the screen size
  img.src = image + (isMobile ? ImageSize.Medium : ImageSize.Large) as string;
  // Set the onload event handler to trigger when the image is loaded
  img.onload = onLoadModalImage;
};

/**
 * Closes the image modal by resetting its visibility and image source.
 */
const closeImageModal = (): void => {
  selectedModalImage.value = undefined;
  isImageModalVisible.value = false;
};

/**
 * Event handler triggered when the image in the modal is loaded.
 *
 * This function updates the loading state of the image modal.
 */
const onLoadModalImage = (): void => {
  // Set the loading state to false when the image has loaded
  isLoadingImageModal.value = false;
};


</script>

<template>
  <!-- Check Access Permission -->
  <template v-if="showNew">
    <Loading v-if="isLoading" />

    <!-- No Views Found -->
    <AlertBanner v-else-if="noViewsFound"
                 :variant="AlertVariant.Warning"
                 :icon-name="IconName.InformationCircleIcon"
                 :icon-style="IconStyle.Outline">
      <template #mainContent>
        <span class="text--size-5">
          <Heading level="5">
            Unable to create a video
          </Heading>
          No views meet the criteria. A view must exist and must have uploaded images from the view.
        </span>
      </template>
    </AlertBanner>

    <!-- Content -->
    <section v-else>
      <form @submit.prevent="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              Information
            </Heading>
            <p>
              Create a video from a range of available images
            </p>
            <!-- Display some progress messages -->
            <section v-if="currentSectionNumber >= 0" class="mb-20">
              <p v-if="currentSectionNumber >= 0">
                <strong>Step 1 of 4:</strong> Select a View
              </p>
              <p v-if="currentSectionNumber >= 1">
                <strong>Step 2 of 4:</strong> Select a Start and End Date Range
              </p>
              <p v-if="currentSectionNumber >= 2">
                <strong>Step 3 of 4:</strong> Select a Start and End Time
              </p>
              <p v-if="currentSectionNumber >= 3">
                <strong>Step 4 of 4:</strong> Select a preferred video Resolution
              </p>
            </section>
          </div>

          <div class="fields">
            <!-- Select View Name -->
            <template v-if="currentSectionNumber === 0">
              <section class="field">
                <label for="model">View</label>
                <v-select v-model="viewValue"
                          :options="filteredViews"
                          :reduce="(view: client.View) => view.id"
                          placeholder="Select a view"
                          :clearable="false"
                          label="name"
                          :disabled="isGettingImages || !!props.viewId"
                          @option:selected="viewSelected"
                          @search="(search: string) => {
                            filteredViews = filteredViews.filter((x: client.View) => {
                              if (!!search.length) return true;
                              return x.name.toLowerCase().includes(search.toLowerCase());
                            });
                          }" />
                <ErrorMessage name="view" class="message message-error" as="p" />
              </section>

              <!-- Show loading banner when fetching images -->
              <AlertBanner v-if="isGettingImages" :variant="AlertVariant.Light">
                <template #mainContent>
                  <p>Loading available images, please wait...</p>
                  <LoadingBar />
                </template>
              </AlertBanner>

              <!-- Show success banner when images are available -->
              <AlertBanner v-else-if="selectedViewName && allImages.length > 0" :variant="AlertVariant.Success">
                <template #mainContent>
                  <p>
                    Good news! We've pre-selected {{ formatNumber(availableImageCount) }} images from {{ formattedStartDate }} to {{ formattedEndDate }}.
                  </p>
                </template>
              </AlertBanner>

              <!-- Show danger banner when no images are found, only after fetching completes -->
              <AlertBanner v-else-if="!isGettingImages && selectedViewName && allImages.length === 0" :variant="AlertVariant.Danger">
                <template #mainContent>
                  <p>
                    No images found for {{ selectedViewName }}.
                  </p>
                </template>
              </AlertBanner>
            </template>

            <!-- Select Date Range -->
            <template v-if="currentSectionNumber === 1">
              <section class="row">
                <div class="field">
                  <label for="dateRange">Select a date range</label>

                  <DatePicker ref="calendar"
                              v-model.string="dateRange"
                              title-position="left"
                              :columns="isMobile ? 1 : 2"
                              :first-day-of-week="2"
                              :expanded="true"
                              color="green"
                              :initial-page="initialPage"
                              timezone="UTC"
                              :min-date="minDate"
                              :max-date="maxDate"
                              :is-range="true"
                              :disabled="isImagesInitialised || isGettingImages"
                              :attributes="imagesCapturedOn"
                              @update:model-value="onUpdateDateRange" />
                  <ErrorMessage name="dateRange.start" class="message message-error" as="p" />
                  <ErrorMessage name="dateRange.end" class="message message-error" as="p" />
                </div>
              </section>

              <section class="row-half">
                <div class="field">
                  <Heading level="6" :has-bottom-margin="true">
                    First image in range
                  </Heading>
                  <div class="date-picker-image">
                    <div v-if="isGettingImages || !firstImageSource || !firstCapturedAt" class="date-picker-image__skeleton" />
                    <div v-else class="date-picker-image__container">
                      <img :src="`${firstImageSource}${isMobile ? ImageSize.Thumbnail : ImageSize.Medium}`"
                           alt="First Image"
                           :aria-hidden="true"
                           @click="openImageModal(firstImageSource, firstCapturedAt)">
                      <span v-if="firstCapturedAt" class="timestamp">
                        {{ dayjs.utc(firstCapturedAt).format('DD/MM/YYYY HH:mm A') }}
                      </span>
                      <MagnifyingGlassIcon class="magnifying-glass-icon" />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <Heading level="6" :has-bottom-margin="true">
                    Last image in range
                  </Heading>
                  <div class="date-picker-image">
                    <div v-if="isGettingImages || !lastImageSource || !lastCapturedAt" class="date-picker-image__skeleton" />
                    <div v-else class="date-picker-image__container">
                      <img :src="`${lastImageSource}${isMobile ? ImageSize.Thumbnail : ImageSize.Medium}`"
                           alt="Last Image"
                           :aria-hidden="true"
                           @click="openImageModal(lastImageSource, lastCapturedAt)">
                      <span v-if="lastCapturedAt" class="timestamp">
                        {{ dayjs.utc(lastCapturedAt).format('DD/MM/YYYY HH:mm A') }}
                      </span>
                      <MagnifyingGlassIcon class="magnifying-glass-icon" />
                    </div>
                  </div>
                </div>
              </section>

              <AlertBanner v-if="isGettingImages" :variant="AlertVariant.Light">
                <template #mainContent>
                  <p>Loading images for the selected date range, please wait...</p>
                  <LoadingBar />
                </template>
              </AlertBanner>
              <AlertBanner v-else-if="selectedViewName && filteredImageCount > 0" :variant="AlertVariant.Success">
                <template #mainContent>
                  <p>
                    {{ formatNumber(filteredImageCount) }} images found for {{ formattedStartDate }} {{ startTimeValue }} to {{ formattedEndDate }} {{ endTimeValue }}
                  </p>
                </template>
              </AlertBanner>
              <AlertBanner v-else-if="selectedViewName && filteredImageCount === 0" :variant="AlertVariant.Danger">
                <template #mainContent>
                  <p>
                    No images found for the selected date range. Please select a different date range.
                  </p>
                </template>
              </AlertBanner>
            </template>

            <!-- Select Time Range -->
            <template v-if="currentSectionNumber === 2">
              <section class="row-third">
                <div class="field">
                  <label class="label">Start Time</label>
                  <input v-model="startTimeValue"
                         :class="{ 'input-error': startTimeMeta.touched && !startTimeMeta.valid }"
                         name="startTime"
                         :disabled="isGettingImages"
                         type="time"
                         @input="startTimeMeta.touched = true"
                         @blur="handleTimeRangeChange">
                  <ErrorMessage name="startTime" class="message message-error" as="p" />
                </div>

                <div class="field">
                  <label class="label">End Time</label>
                  <input v-model="endTimeValue"
                         :class="{ 'input-error': endTimeMeta.touched && !endTimeMeta.valid }"
                         name="endTime"
                         :disabled="isGettingImages"
                         type="time"
                         @input="endTimeMeta.touched = true"
                         @blur="handleTimeRangeChange">
                  <ErrorMessage name="endTime" class="message message-error" as="p" />
                </div>
                <div class="field">
                  <label>Reset Time Range</label>
                  <ButtonComponent :variant="ButtonVariant.Dark"
                                   :is-outline-btn="true"
                                   :is-block-btn="true"
                                   :disabled="((startTimeValue === '00:00' && endTimeValue === '23:59') || isGettingImages)"
                                   @click="resetTimeRange">
                    Reset Times
                  </ButtonComponent>
                </div>
              </section>

              <AlertBanner v-if="isGettingImages" :variant="AlertVariant.Light">
                <template #mainContent>
                  <p>Loading images for the selected time range, please wait...</p>
                  <LoadingBar />
                </template>
              </AlertBanner>

              <AlertBanner v-else-if="!startTimeMeta.valid || !endTimeMeta.valid" :variant="AlertVariant.Danger">
                <template #mainContent>
                  <p>
                    {{ !startTimeMeta.valid ? 'The Start Time must be before End Time' : '' }}
                    {{ !endTimeMeta.valid ? 'The End Time must be after Start Time' : '' }}
                  </p>
                </template>
              </AlertBanner>

              <template v-else-if="filteredImageCount > 0">
                <AlertBanner :variant="AlertVariant.Success" :has-bottom-margin="true">
                  <template #mainContent>
                    <p>
                      Available images captured daily between {{ startTimeValue }} and {{ endTimeValue }},
                      from {{ formattedStartDate }} to {{ formattedEndDate }}:
                      <strong>{{ formatNumber(filteredImageCount) }}</strong>
                    </p>
                  </template>
                </AlertBanner>

                <AlertBanner :variant="AlertVariant.Info"
                             :icon-name="IconName.InformationCircleIcon"
                             :icon-style="IconStyle.Outline">
                  <template #mainContent>
                    <Heading level="5" :has-bottom-margin="true">
                      Please note:
                    </Heading>
                    <p>The video will be created using the combined date range <strong>and</strong> time range.</p>
                    <p>For example, if you select a date range from January through to December but then select a time range to 01:00 to 02:00, the video will be created using images only taken between 01:00 and 02:00 for the given date range.</p>
                  </template>
                </AlertBanner>
              </template>

              <AlertBanner v-else :variant="AlertVariant.Danger">
                <template #mainContent>
                  No images found for the selected time range. Please select a different time range.
                </template>
              </AlertBanner>
            </template>

            <!-- Select Settings -->
            <template v-if="currentSectionNumber === 3">
              <section class="row-half">
                <div class="field">
                  <label class="label" for="resolution">Resolution</label>
                  <ResolutionDropDown @on-selected="(value: Resolution) => (resolutionValue = value)" />
                  <ErrorMessage name="resolution" class="message message-error" as="p" />
                </div>
                <div class="field">
                  <label class="label" for="frameRate">Frame Rate</label>
                  <input v-model="frameRateValue"
                         disabled
                         class="input"
                         name="dailyStart"
                         type="number">
                  <ErrorMessage name="frameRate" class="message message-error" as="p" />
                </div>
              </section>
              <div class="row-half">
                <div class="field">
                  <label class="label" for="duration">Duration (seconds)</label>
                  <input v-model="durationValue"
                         disabled
                         class="input"
                         type="number">
                  <ErrorMessage name="duration" class="message message-error" as="p" />
                </div>
                <div class="field" />
              </div>

              <Loading v-if="isGettingImages" />
              <div v-else class="row-full">
                <AlertBanner :variant="AlertVariant.Light" :has-bottom-margin="true">
                  <template #mainContent>
                    <Heading level="5" :has-bottom-margin="true">
                      Selection Summary
                    </Heading>
                    <ul class="pl-20 mb-20">
                      <li><strong>View:</strong> {{ selectedViewName }}</li>
                      <li><strong>Date Range:</strong> {{ formattedStartDate }} to {{ formattedEndDate }}</li>
                      <li><strong>Time Range:</strong> {{ startTimeValue }} to {{ endTimeValue }}</li>
                      <li><strong>Filtered Image Count:</strong> {{ formatNumber(filteredImageCount) }}</li>
                    </ul>
                    <p>Please review your selection before creating a video.</p>
                  </template>
                </AlertBanner>

                <AlertBanner v-if="currentSectionNumber >= 3"
                             :variant="AlertVariant.Warning"
                             :icon-name="IconName.ExclamationTriangleIcon"
                             :icon-style="IconStyle.Outline"
                             :has-bottom-margin="false"
                             :has-shadow="true">
                  <template #mainContent>
                    Please be aware that our algorithm optimises the number of images included in the video creation process and may not use all of the images you have selected.
                  </template>
                </AlertBanner>
              </div>
            </template>
          </div>
        </div>

        <ButtonContainer>
          <ButtonComponent :is-block-btn="true"
                           :is-outline-btn="true"
                           :variant="ButtonVariant.Dark"
                           @click="cancelBtn">
            Cancel
          </ButtonComponent>

          <ButtonComponent v-if="currentSectionNumber !== firstSectionNumber"
                           :disabled="isPreviousBtnDisabled"
                           :is-block-btn="true"
                           :is-outline-btn="true"
                           :icon-position="IconPosition.Left"
                           :icon-name="IconName.ChevronLeftIcon"
                           :variant="ButtonVariant.Dark"
                           @click="currentSectionNumber--">
            Previous
          </ButtonComponent>
          <ButtonComponent v-if="currentSectionNumber !== lastSectionNumber"
                           :disabled="isNextBtnDisabled"
                           :loading="isGettingImages"
                           :is-block-btn="true"
                           :icon-position="IconPosition.Right"
                           :icon-name="IconName.ChevronRightIcon"
                           :variant="ButtonVariant.Dark"
                           @click="currentSectionNumber++">
            Next
          </ButtonComponent>

          <ButtonComponent v-if="currentSectionNumber === lastSectionNumber"
                           :type="ButtonType.Submit"
                           :is-block-btn="true"
                           :variant="ButtonVariant.Dark"
                           :loading="isSubmitting || isGettingImages"
                           :disabled="createButtonDisabled"
                           @click="onSubmit">
            Create Video
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </section>
  </template>

  <!-- Unauthorised Access -->
  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>

  <!-- Image Modal -->
  <ModalComponent :visible="isImageModalVisible"
                  :heading-title="`Viewing image: ${dayjs.utc(selectedModalCapturedAt).format('DD/MM/YYYY HH:mm A')}`"
                  :is-large="true"
                  @on-close="closeImageModal">
    <template #modal-content>
      <Loading v-if="isLoadingImageModal"
               :is-absolute-positioned="true" />
      <div v-else class="modal-image__container">
        <img v-if="selectedModalImage"
             class="modal-image"
             :src="`${selectedModalImage}${isMobile ? ImageSize.Medium : ImageSize.Large}`"
             alt="Image preview"
             :aria-hidden="true"
             @load="onLoadModalImage">
        <span v-if="selectedModalCapturedAt && !isMobile" class="modal-image__timestamp">
          {{ dayjs.utc(selectedModalCapturedAt).format('DD/MM/YYYY HH:mm A') }}
        </span>
      </div>
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       @click="closeImageModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.date-picker-image {
  position: relative;
  background: $neutral-200;
  border-radius: .5rem;
  overflow: hidden;

  &__skeleton {
    width: 100%;
    aspect-ratio: 3 / 2;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    border-radius: .5rem;
    animation: pulse 2000ms ease-in-out infinite;
  }

  &__container {
    display: flex;
    flex-direction: column;

    &:hover .magnifying-glass-icon {
      opacity: 1;
      fill: $neutral-800;
    }

    & img {
      position: relative;
      display: block;
      max-width: 100%;
      aspect-ratio: 3 / 2;
      object-fit: cover;
      border-radius: .5rem;
      overflow: hidden;
      opacity: 1;
      transition: transform 200ms ease-in, opacity 200ms ease-in;
      background-color: $neutral-900;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.7;
      }
    }

    & .timestamp {
      position: absolute;
      bottom: 10px;
      right: 10px;
      background: rgba(0, 0, 0, 0.6);
      color: $neutral-50;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: .775rem;
    }

    & .magnifying-glass-icon {
      position: absolute;
      inset: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.7;
      fill: $neutral-50;
      width: 48px;
      height: 48px;
      transition: opacity 200ms ease-in, fill 200ms ease-in;
      pointer-events: none;
    }
  }
}


// Modal
:deep(.modal__content--body) {
  aspect-ratio: 3 / 2;
  margin: auto;
}

.modal-image {
  display: block;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  height: auto;

  &__container {
    max-width: 96%;
    margin: auto;
    text-align: center;
  }

  &__timestamp {
    display: block;
    margin-top: 8px;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    color: $neutral-50;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.775rem;
  }
}
</style>
