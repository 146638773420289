<script lang="ts" setup>
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import NewVideoForm from '@components/sequencer/NewVideoForm.vue';
import SubHeader from '@components/SubHeader.vue';

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Videos, to: BreadcrumbPaths.Videos },
  { title: BreadcrumbTitles.CreateVideo, active: true },
];
</script>

<template>
  <SubHeader heading="Create Video" level="2" />

  <Breadcrumb :is-sticky="true" :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <NewVideoForm />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
