<script setup lang="ts">
import Heading from '@components/Heading.vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';

// Interface for props
interface ForbiddenStateProps {
  headingText: string | '';
  headingLevel?: '1' | '2' | '3' | '4' | '5' | '6';
}

// Define the props
const props = defineProps<ForbiddenStateProps>();

// Set the defaults
const headingLevel = props.headingLevel || '2';
</script>

<template>
  <div class="forbidden">
    <ExclamationTriangleIcon class="forbidden__icon" />
    <Heading :level="headingLevel">
      {{ headingText }}
    </Heading>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.forbidden {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  min-height: 300px;

  &__icon {
    width: 50px;
    height: 50px;
    opacity: 0.25;
  }
}
</style>
