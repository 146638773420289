<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EditUserForm from '@components/account_forms/EditUserForm.vue';
import { ref } from 'vue';

const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;
const firstName = ref<string>('');
const lastName = ref<string>('');
const userName = ref<string>('Loading...');

/**
 * Handles the update of user data emitted by the EditUserForm so that we have a userName for SubHeader
 *
 * @param {client.User} user - The updated user data.
 */
function handleUserUpdate(user: client.User): void {
  firstName.value = user.forename || '';
  lastName.value = user.surname || '';
  userName.value = `${firstName.value} ${lastName.value}`.trim();
}
</script>

<template>
  <SubHeader :heading="userName"
             level="2">
    <template #buttons>
      <ButtonComponent :is-block-btn="true"
                       to="/logout"
                       :variant="ButtonVariant.Dark">
        Logout
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       to="/change-password"
                       :variant="ButtonVariant.Dark">
        Change Password
      </ButtonComponent>
    </template>
  </SubHeader>

  <EditUserForm v-if="activeUser"
                :user-id="activeUser.id"
                @update:user="handleUserUpdate"
                @on-updated="applicationStore.setActiveUser()" />
</template>
