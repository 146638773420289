<script setup lang="ts">
interface SkeletonLoadingProps {
  variant: string;
  lineCount?: number;
}

const props = defineProps<SkeletonLoadingProps>();
const variant = props.variant || 'default';
const lineCount = props.lineCount || 3; // Default to 3 lines
</script>

<template>
  <div :class="`skeleton skeleton-${variant}`">
    <!-- Card and Default variants -->
    <template v-if="variant === 'card' || variant === 'default'">
      <div class="skeleton-card__header" />
      <div class="skeleton-card__content">
        <div v-for="n in lineCount" :key="n" class="skeleton-card__content--line" />
      </div>
      <div class="skeleton-card__footer">
        <div class="skeleton-card__footer--button" />
        <div class="skeleton-card__footer--button" />
      </div>
    </template>

    <!-- List and Table variants -->
    <template v-else-if="variant === 'list' || variant === 'table'">
      <div class="skeleton-card__content">
        <div v-for="n in lineCount" :key="n" class="skeleton-card__content--line" />
      </div>
    </template>

    <!-- Button -->
    <template v-else-if="variant === 'button'">
      <div />
    </template>

    <!-- Button Small -->
    <template v-else-if="variant === 'button-small'">
      <div />
    </template>

    <slot v-else />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.skeleton {
  --initial-color: #f0f0f0;
  --final-color: #e0e0e0;

  background: linear-gradient(90deg, var(--initial-color), var(--final-color), var(--initial-color));
  background-size: 200% 100%;
  border-radius: 6px;
  animation: pulse 3000ms ease-in-out infinite;

  &-button {
    width: 120px;
    height: 40px;
    background-color: var(--initial-color);
    border-radius: 8px;

    &-small {
      width: 70px;
      height: 33px;
    }
  }
}

/* Card and Default variant */
.skeleton-card {
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);

  &__header {
    width: 200px;
    height: 30px;
    margin-bottom: $margin-bottom;
    background-color: var(--initial-color);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--line {
      width: 100%;
      height: 24px;
      background-color: var(--initial-color);
    }
  }

  &__footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: $margin-bottom;

    &--button {
      width: 80px;
      height: 40px;
      background-color: var(--initial-color);
      border-radius: 8px;
    }
  }
}
</style>
