<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const props = defineProps<{
  updateUser: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
}>();

const isSubmitting: Ref<boolean> = ref(false);

interface SetNameForm {
  forename: string;
  surname: string;
}

const schema = yup.object({
  forename: yup.string().required(),
  surname: yup.string().required(),
});

const { handleSubmit } = useForm<SetNameForm>({
  validationSchema: schema,
});

const { value: forenameValue, errorMessage: forenameError } = useField<string>('forename', 'forename', {
  modelPropName: 'forename',
});

const { value: surnameValue, errorMessage: surnameError } = useField<string>('surname', 'surname', {
  modelPropName: 'surname',
});

const onSubmit = handleSubmit(async (values, actions) => {
  isSubmitting.value = true;
  try {
    await props.updateUser(values.forename, values.surname);
    emit('onSuccess');
  } catch (error: any) {
    actions.setErrors({ forename: 'Error occurred' });
    isSubmitting.value = false;
    console.error(error);
    return;
  }
});
</script>

<template>
  <form @submit.prevent="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <div class="field">
          <label for="forename">First Name</label>
          <input id="forename"
                 v-model="forenameValue"
                 type="text"
                 autocomplete="given-name">
          <p class="message message-error">
            {{ forenameError }}
          </p>
        </div>
      </div>
      <div class="fields">
        <div class="field">
          <label for="surname">Last Name</label>
          <input id="surname"
                 v-model="surnameValue"
                 type="text"
                 autocomplete="family-name">
          <p class="message message-error">
            {{ surnameError }}
          </p>
        </div>
      </div>
    </section>

    <ButtonContainer>
      <ButtonComponent :disabled="isSubmitting"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Submit
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
