<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BreadcrumbPaths, ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

interface ViewCreationForm {
  name: string;
  camera: string;
  client: string;
  project: string;
}

const schema = yup.object({
  name: yup.string().required(),
  camera: yup.string().nullable(),
  client: yup.string().nullable(),
  project: yup.string().nullable(),
});

const { handleSubmit } = useForm<ViewCreationForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: cameraValue, errorMessage: cameraError } = useField<string>('camera', 'camera');
const { value: clientValue, errorMessage: clientError } = useField<string>('client', 'client');
const { value: projectValue, errorMessage: projectError } = useField<string>('project', 'project');

const router = useRouter();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

const cameras = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });
let filteredCameras = [...cameras.data];

const clients = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
let filteredClients = [...clients.data];

const projects = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
let filteredProjects = [...projects.data];

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  if (applicationStore.activeOrganisation == undefined) return;
  const request: client.CreateViewRequest = {
    name: values.name,
    camera: values.camera || undefined,
    organisation: applicationStore.activeOrganisation.id,
    isPublic: false,
    client: values.client || undefined,
    project: values.project || undefined,
  };
  const response = await viewStore.createView(request);

  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    isSubmitting.value = false;
    return;
  }
  applicationStore.publishSuccessNotification({
    text: 'Successfully created view.',
    autoCloseMs: 3000,
  });
  router.push({ name: 'views' });
});

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /views
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Views as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          View Information
        </Heading>
        <p>Create a new view.</p>
      </div>

      <div class="fields">
        <div class="field">
          <label for="view-name">Name</label>
          <input id="view-name"
                 v-model="nameValue"
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>
        <div class="field">
          <label for="model">Client</label>
          <v-select v-model="clientValue"
                    label="name"
                    :options="filteredClients"
                    :reduce="(client: client.Client) => client.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredClients = clients.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.name.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <p class="message message-error">
            {{ clientError }}
          </p>
        </div>

        <div class="field">
          <label for="model">Project</label>
          <v-select v-model="projectValue"
                    label="name"
                    :options="filteredProjects"
                    :reduce="(project: client.Project) => project.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredProjects = projects.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.name.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <p class="message message-error">
            {{ projectError }}
          </p>
        </div>
        <div class="field">
          <label for="model">Camera</label>
          <v-select v-model="cameraValue"
                    label="serialNumber"
                    :options="filteredCameras"
                    :reduce="(camera: client.Camera) => camera.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredCameras = cameras.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.serialNumber.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <p class="message message-error">
            {{ cameraError }}
          </p>
        </div>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :disabled="isSubmitting"
                       :type="ButtonType.Submit"
                       :is-block-btn="true">
        Create
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
