<script setup lang="ts">
import { computed, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';
import Heading from '@components/Heading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import { BadgeVariant, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

// Download Card Interface
const props = defineProps<{
  resource: client.BatchZip | null; // Accept null initially to indicate loading state
  selected?: boolean;
}>();

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Permissions
const canDownload = applicationStore.canUser(client.Entitlements.READ_BATCH_DOWNLOAD, applicationStore.activeOrganisation!);

// Batch References
const batchStatus = ref<client.BatchZipStatus>(props.resource?.status || client.BatchZipStatus.INITIATED);
const batchFileName = ref(props.resource?.fileName);
const batchCreatedAt = computed(() =>
  props.resource?.createdAt
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource?.createdAt))
    : 'Never',
);
const batchFilesInDownload = ref(props.resource?.fileUrls.length);
const batchDownloadUrl = ref(props.resource?.signedDownloadUrl);

// Badge variant mapping
const badgeVariantMapping = {
  [client.BatchZipStatus.COMPLETE]: BadgeVariant.Success,
  [client.BatchZipStatus.PROCESSING]: BadgeVariant.Danger,
  [client.BatchZipStatus.INITIATED]: BadgeVariant.Warning,
};

// Compute Badge Variants
const badgeVariant = computed(() => badgeVariantMapping[batchStatus.value] || BadgeVariant.Danger);

function downloadBatchZip(): void {
  if (batchDownloadUrl.value) {
    // Create a temporary anchor element to trigger the download
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = batchDownloadUrl.value;
    link.setAttribute('download', `${batchFileName.value}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Show success notification
    applicationStore.publishSuccessNotification({
      text: 'Downloading zip file, please check your browser’s downloads folder',
      autoCloseMs: 3000,
    });
  } else {
    console.error('Batch download URL not found');
  }
}
</script>

<template>
  <div :class="['download-card',`download-card--${batchStatus.toLowerCase()}`]">
    <div v-if="batchStatus" class="download-card__status">
      <BadgeComponent :variant="badgeVariant">
        {{ batchStatus }}
      </BadgeComponent>
    </div>

    <div v-if="batchFileName" class="download-card__header">
      <Heading level="4" class="download-card__header--title">
        {{ batchFileName }}
      </Heading>
    </div>

    <div class="download-card__content">
      <div class="text--truncate">
        <span class="text--bold">Images in batch:</span> {{ batchFilesInDownload }}
      </div>
      <div>
        <span class="text--bold">Created at:</span> {{ batchCreatedAt }}
      </div>
    </div>

    <div class="download-card__actions">
      <ButtonComponent v-if="canDownload"
                       :variant="ButtonVariant.Primary"
                       :is-icon-btn="true"
                       :icon-name="IconName.ArrowDownTrayIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Download Batch"
                       @click="downloadBatchZip" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.download-card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: $gap-mobile;
  background-color: $neutral-100;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutral-300;
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &--initiated {
    background-color: $red-100;
    box-shadow: inset 0 0 0 1px $red-300;
  }

  &--processing {
    background-color: $orange-50;
    box-shadow: inset 0 0 0 1px $orange-300;
  }

  &--active {
    background-color: $green-200;
    box-shadow: inset 0 0 0 1px $green-800;
  }

  &__status {
    align-self: flex-end;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-sm) {
        flex-basis: 2.4em;
      }
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: .875rem;
    color: $neutral-600;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>