<script setup lang="ts">
import { ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import EditUserForm from '@components/account_forms/EditUserForm.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubHeader from '@components/SubHeader.vue';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

// Page Constants
const route = useRoute();
const userId: string = route.params['id'] as string;
const firstName = ref<string>('');
const lastName = ref<string>('');
const userName = ref<string>('Loading...');

const breadcrumbs = ref<BreadCrumbItem[]>([
  { title: BreadcrumbTitles.AllUsers, to: BreadcrumbPaths.AllUsers },
  { title: userName.value, active: true },
]);

/**
 * Handles the update of user data emitted by the EditUserForm so that we have a userName for SubHeader & Breadcrumb.
 *
 * @param {client.User} user - The updated user data.
 */
function handleUserUpdate(user: client.User): void {
  firstName.value = user.forename || '';
  lastName.value = user.surname || '';
  userName.value = `${firstName.value} ${lastName.value}`.trim();
  // Dynamically update the breadcrumbs
  breadcrumbs.value.splice(1, 1, { title: userName.value, active: true });
}
</script>

<template>
  <div>
    <SubHeader :heading="`Edit User ${userName}`" level="2" />
    <Breadcrumb :is-sticky="true" :items="breadcrumbs" />
    <EditUserForm :user-id="userId" @update:user="handleUserUpdate" />
  </div>
</template>
