import type { Router } from 'vue-router';

const EXCLUDE_PATHS = ['/login'];

/**
 * Returns the continue URL based on the current route and query parameters.
 *
 * If a 'continueUrl' query parameter is present, it will be parsed as an absolute URL.
 * If the 'continueUrl' is not an absolute URL, it will be resolved against the current origin.
 *
 * If no 'continueUrl' query parameter is present, the current path will be returned.
 * However, if the current path is in the EXCLUDE_PATHS list, '/views' will be returned instead.
 *
 * @param router The Vue Router instance.
 * @returns The continue URL or undefined if it cannot be determined.
 */
export const getContinueUrl = (router: Router): string | undefined => {
  const continueUrl = router.currentRoute.value.query['continueUrl'];

  if (continueUrl) {
    try {
      // Check if the continueUrl is an absolute URL
      const absoluteUrl = new URL(String(continueUrl));
      return absoluteUrl.pathname;
    } catch (error) {
      console.warn(error);

      // If it's not an absolute URL, assume it's a relative URL and resolve it against the current origin
      const currentOrigin = window.location.origin;
      const resolvedUrl = new URL(String(continueUrl), currentOrigin);
      return resolvedUrl.pathname;
    }
  }

  const currentPath = router.currentRoute.value.path;
  return EXCLUDE_PATHS.includes(currentPath) ? '/views' : currentPath;
};
