<script setup lang="ts">
import { Ref, ref } from 'vue';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import { extractErrorMessage } from '@utils/errorUtils';
import { useApplicationStore } from '@stores/application';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const applicationStore = useApplicationStore();

const props = defineProps<{
  resetPassword: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
}>();

const isSubmitting: Ref<boolean> = ref(false);
const isPasswordVisible: Ref<boolean> = ref(false);

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

// Set up form validation schema
const { handleSubmit, meta } = useForm<ResetPasswordForm>({
  validationSchema: yup.object({
    password: yup
      .string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(200, 'Password must be less than 200 characters')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/\d/, 'Password must contain at least one number')
      .matches(/[!@#$£%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Both passwords must match')
      .required('Password is required'),
  }),
});

// Set up fields for password and confirmPassword with error messages
const { value: passwordValue, meta: passwordMeta, } = useField<string>('password', { validateOnValueUpdate: true });
const {value: confirmPasswordValue, meta: confirmPasswordMeta } = useField<string>('confirmPassword', { validateOnValueUpdate: true });

/**
 * Submit handler for the reset password form.
 *
 * @param {Object} values - The form values.
 * @param {Object} actions - The form actions.
 */
const onSubmit = handleSubmit(async (values, actions) => {
  isSubmitting.value = true;

  try {
    // Attempt to reset the password
    await props.resetPassword(values.password);

    // Emit success event if successful
    emit('onSuccess');
  } catch (error: unknown) {
    // Extract the error message
    const errorMessage = extractErrorMessage(error);

    // Handle specific field-related errors or display a general notification
    if (errorMessage.includes('Invalid login details')) {
      // Set a specific error for the password field
      actions.setErrors({ password: errorMessage });
    } else {
      // For any unexpected error, log it and show a generic notification
      console.error('Unexpected error during password reset:', error);
      applicationStore.publishErrorNotification({
        text: 'An unexpected error occurred while resetting the password. Please try again later.',
      });
    }
  } finally {
    isSubmitting.value = false;
  }
});

// Toggle password visibility
function togglePasswordVisibility(): void {
  isPasswordVisible.value = !isPasswordVisible.value;
}
</script>

<template>
  <form @submit="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <div class="field">
          <label for="password">Password</label>
          <input id="password"
                 v-model="passwordValue"
                 :type="isPasswordVisible ? 'text' : 'password'"
                 autocomplete="new-password"
                 :class="{ 'input-error': passwordMeta.touched && !passwordMeta.valid, 'input-valid': passwordMeta.valid }"
                 @input="passwordMeta.touched = true">
          <ErrorMessage name="password" class="message message-error" as="p" />
        </div>
        <div class="field">
          <label for="confirmPassword">Confirm password</label>
          <input id="confirmPassword"
                 v-model="confirmPasswordValue"
                 :type="isPasswordVisible ? 'text' : 'password'"
                 autocomplete="new-password"
                 :class="{ 'input-error': confirmPasswordMeta.touched && !confirmPasswordMeta.valid, 'input-valid': confirmPasswordMeta.valid }"
                 @input="confirmPasswordMeta.touched = true">
          <ErrorMessage name="confirmPassword" class="message message-error" as="p" />
        </div>
      </div>
    </section>

    <ButtonContainer justify-content="space-between">
      <ButtonComponent :type="ButtonType.Button"
                       :variant="ButtonVariant.Light"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       @click="togglePasswordVisibility">
        {{ isPasswordVisible ? 'Hide' : 'Show' }} Passwords
      </ButtonComponent>

      <ButtonComponent :disabled="!meta.valid"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Set Password
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
