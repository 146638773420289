<script setup lang="ts">
import { ref } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DatePicker } from 'v-calendar';

const calendar = ref();

const props = defineProps<{
  modelValue: { start: dayjs.Dayjs; end: dayjs.Dayjs };
}>();

const range = ref({
  start: props.modelValue.start.toDate(),
  end: props.modelValue.end.toDate(),
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: { start: dayjs.Dayjs; end: dayjs.Dayjs }): void;
}>();

function onUpdateDate(e: { start: dayjs.Dayjs; end: dayjs.Dayjs }): void {
  emit('update:modelValue', {
    start: dayjs(e.start).startOf('day'),
    end: dayjs(e.end).startOf('day').endOf('day'),
  });
}
</script>

<template>
  <div class="energy-calendar">
    <DatePicker ref="calendar"
                v-model.range="range"
                expanded
                mode="date"
                :is-range="true"
                @update:model-value="onUpdateDate" />
  </div>
</template>
