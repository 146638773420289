import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from './application';
import { extractErrorMessage } from '@utils/errorUtils';

export interface PaginationOptions {
  pageNumber: number;
  pageSize: number;
}

export interface PagedResult<T> {
  total_count: number;
  offset: number;
  pageSize?: number;
  pageNumber?: number;
  data: Array<T>;
}

export interface Video extends client.Sequence {
  name: string;
  clientId?: string;
  viewName?: string;
  thumbnail?: string;
  length?: number;
  url?: string;
  downloadURL?: string;
}

export interface DeleteVideo {
  sequenceId: string;
}

export const useVideoStore = defineStore('useVideoStore', () => {
  const applicationStore = useApplicationStore();
  const videos = ref<PagedResult<Video>>({ data: [], total_count: 0, offset: 0 });
  const paginationOptions = ref<PaginationOptions>({ pageNumber: 1, pageSize: 12 });
  const videoSelected = ref<Video>();
  const videoSelectedForDeletion = ref<Video>();

  const showDelete = applicationStore.canUser(client.Entitlements.DELETE_SEQUENCE, applicationStore.activeOrganisation!);

  const selectedFilters = ref<{ client: string | undefined; view: string | undefined }>({
    client: undefined,
    view: undefined,
  });

  async function getVideos(
    viewId: string | undefined = undefined,
    sortBy: string | undefined = '-createdAt'
  ): Promise<PagedResult<Video>> {
    const sequencesResult = await client.listSequences({
      organisation: applicationStore.activeOrganisation!.id,
      viewId,
      sort: sortBy,
      page: paginationOptions.value.pageNumber,
      limit: paginationOptions.value.pageSize,
    });
    const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id }); // Either return data in api or filter by view IDs

    let sequencesData = sequencesResult.data;

    videos.value.total_count = sequencesResult.total_count;
    videos.value.pageSize = paginationOptions.value.pageSize;
    if (paginationOptions.value.pageNumber) videos.value.pageNumber = paginationOptions.value.pageNumber - 1;
    if (paginationOptions.value.pageSize && paginationOptions.value.pageNumber)
      videos.value.offset = paginationOptions.value.pageSize * (paginationOptions.value.pageNumber - 1);

    let tempVideos = sequencesData.map((sequence) => {
      const view = viewsResult.data.find((x) => x.id == sequence.view);
      const name = sequence.name ?? view?.name;
      let downloadURL = sequence.downloadURL;
      if (downloadURL) downloadURL = downloadURL + `?filename=${generateDownloadFileName(name)}.mp4`;
      return {
        ...sequence,
        name: name,
        viewName: view?.name,
        length: sequence?.duration ?? 0,
        thumbnail: sequence.thumbnailUrl,
        url: sequence.iframeUrl,
        clientId: view?.client,
        downloadURL: downloadURL,
        //lastSeen: view?.lastSeenUtc !== undefined ? new Date(view?.lastSeenUtc) : undefined,
      } as Video;
    });

    tempVideos = tempVideos.filter((video) => {
      if (selectedFilters.value.client)
        return selectedFilters.value.client ? video.clientId === selectedFilters.value.client : true;
      if (selectedFilters.value.view)
        return selectedFilters.value.view ? video.view === selectedFilters.value.view : true;
      return true;
    });
    videos.value.data = tempVideos;
    return videos.value;
  }
  function setPagination(page = 1, pageSize = 12): void {
    paginationOptions.value.pageNumber = page;
    paginationOptions.value.pageSize = pageSize;
  }

  /**
   * Generates a safe download file name based on the provided name.
   * If the provided name is null, undefined, or empty, a unique timestamp fallback is used.
   * The generated file name replaces any characters not in the specified set (a-zA-Z0-9-_) with a hyphen.
   *
   * @param name The name to use for the download file, or null/undefined for a timestamp fallback.
   * @returns A safe download file name.
   */
  function generateDownloadFileName(name: string | null | undefined): string {
    // Use a fallback name if the provided name is null, undefined, or empty
    const fallbackName = 'download-' + new Date().toISOString().replace(/[:.]/g, '-'); // Unique timestamp fallback

    // If name is null, undefined, or empty string, use the fallback
    const safeName = name?.trim() ? name : fallbackName;

    // Define the regular expression pattern to match characters not in the specified set
    const pattern = /[^a-zA-Z0-9-_]/g;

    // Use the replace method to replace characters with an empty string
    return safeName.replace(pattern, '-');
  }

  /**
   * Deletes a video with the specified sequence ID.
   *
   * @param sequenceId The sequence ID of the video to delete.
   * @throws {Error} If the user does not have permission to delete videos or if the sequence ID is invalid or missing.
   */
  async function deleteVideo(sequenceId: DeleteVideo): Promise<void> {

    if (!showDelete) {
      applicationStore.publishErrorNotification({ text: 'You do not have permission to delete videos.' });
      throw new Error('You do not have permission to delete videos.');
    }

    if (!sequenceId?.sequenceId) {
      applicationStore.publishErrorNotification({ text: 'Unable to delete video.' });
      throw new Error('Invalid or missing sequence ID');
    }

    try {
      // Attempt to delete the video using the client API
      await client.deleteSequenceById({ sequenceId: sequenceId.sequenceId });

      // Update the video list immediately after deletion
      await getVideos();

      // Trigger success notification
      applicationStore.publishSuccessNotification({
        text: 'Video deleted successfully.',
        autoCloseMs: 3000,
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      applicationStore.publishErrorNotification({ text: errorMessage || 'Failed to delete video.' });
      console.error('Error deleting video:', errorMessage);
      throw new Error(errorMessage || 'Error deleting video');
    }
  }

  return {
    videos,
    videoSelected,
    videoSelectedForDeletion,
    paginationOptions,
    selectedFilters,
    setPagination,
    getVideos,
    deleteVideo,
  };
});
