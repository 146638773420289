/**
 * Extracts a meaningful error message from a given error object.
 *
 * If the error object has a 'body' property and the 'body' object has an 'error' property,
 * the function returns the 'message' property of the 'error' object. If not, it returns a default error message.
 *
 * @param error The error object to extract the message from.
 * @returns A meaningful error message.
 */
export function extractErrorMessage(error: unknown): string {
  // Check if the error is an Error object
  if (error instanceof Error) {
    // Log the error for debugging
    console.debug("Extracted error:", error);

    // Custom handling for specific Firebase error messages - ChangePasswordForm
    if (error.message.includes("auth/invalid-credential") || error.message.includes("INVALID_LOGIN_CREDENTIALS")) {
      return 'Invalid login details. Please check your password and try again.';
    }

    // Additional custom error handling can go here, e.g., for other APIs or systems
    // Example: if (error.message.includes("some_other_error_code")) { return "Custom message"; }

    // Generic error message from the caught error
    return error.message || 'An unknown error occurred. Please try again.';
  }

  // Generic error message for non-Error type errors
  return 'An unknown error occurred.';
}

