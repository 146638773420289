<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { BadgeVariant, ButtonVariant } from '@viewModels/enums';

import BadgeComponent from '@components/BadgeComponent.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';

const route = useRoute();
const currentCamera = ref<client.Camera>();
const cameraId = route.params['id'] as string;
const isLoading = ref<boolean>(true);

let apiKey = ref<string>('Press "Create API Key" button below to generate your API Key');
const createApiKey = async (): Promise<void> => {
  apiKey.value = (await client.refreshCameraByIdApiKey({ cameraId })).apiKey!;
};

// Get current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <section v-else>
      <form>
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              API Key
            </Heading>
            <p>
              <BadgeComponent :is-pill="true"
                              :variant="BadgeVariant.Info">
                Experimental feature
              </BadgeComponent>
            </p>
            <p>Generate a new API Key for your camera. For security the key will only be shown once.</p>
          </div>
          <div class="fields">
            <p class="message">
              {{ apiKey }}
            </p>
          </div>
        </div>

        <ButtonContainer>
          <ButtonComponent :variant="ButtonVariant.Dark"
                           @click="createApiKey">
            Create API Key
          </ButtonComponent>
        </ButtonContainer>
      </form>
    </section>
  </ContainerCard>
</template>
