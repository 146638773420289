<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { extractErrorMessage } from '@utils/errorUtils';
import { storeToRefs } from 'pinia';
import { BadgeVariant, BreadcrumbPaths, BreadcrumbTitles, ButtonType, ButtonVariant } from '@viewModels/enums';
import Forbidden from '@layouts/Forbidden.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import Heading from '@components/Heading.vue';
import BadgeComponent from '@components/BadgeComponent.vue';


interface BadgeInput {
  value: string | undefined;
  length: number;
  valid: boolean;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Cameras, to: BreadcrumbPaths.Cameras },
  { title: BreadcrumbTitles.UnregisteredCameras, to: BreadcrumbPaths.UnregisteredCameras },
  { title: BreadcrumbTitles.EditCamera, active: true },
];

// Page Constants
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const route = useRoute();
const isSubmitting = ref<boolean>(false);
const cameraId = ref(route.params['id'] as string);
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);

// Form Constants
const alphanumericDashRegex = /^[a-zA-Z0-9-]*$/;
const serialNumberLength = 14;
const wpSerialNumberLength = 14;
const wpImeiLength = 15;

// Form Validation Rules
const { handleSubmit, meta } = useForm({
  validationSchema: yup.object({
    serialNumber: yup
      .string()
      .required('GabrielCam Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(serialNumberLength, `GabrielCam Serial Number must be exactly ${serialNumberLength} characters`),
    wpSerialNumber: yup
      .string()
      .required('Modem Serial Number is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(wpSerialNumberLength, `Modem Serial Number must be exactly ${wpSerialNumberLength} characters`),
    wpImei: yup
      .string()
      .required('Modem IMEI is required')
      .matches(alphanumericDashRegex, 'Only letters, numbers, and hyphens are allowed')
      .length(wpImeiLength, `Modem IMEI must be exactly ${wpImeiLength} characters`),
  }),
});

// Form Values
const status = ref<string>('');
const systemType = ref<string>('');
const { value: serialNumber, meta: serialNumberMeta, resetField: resetSerialNumber } = useField<string>('serialNumber');
const { value: wpSerialNumber, meta: wpSerialNumberMeta, resetField: resetWpSerialNumber } = useField<string>('wpSerialNumber');
const { value: wpImei, meta: wpImeiMeta, resetField: resetWpImei } = useField<string>('wpImei');

/**
 * Returns the badge variant based on the input value, length, and validity.
 *
 * @param {BadgeInput} input - The input object containing value, length, and valid properties.
 * @returns {BadgeVariant} The badge variant corresponding to the input state.
 */
const getBadgeVariant = ({ value, length, valid }: BadgeInput): BadgeVariant => {
  // If the input is empty
  if (!value || value.length === 0) {
    return BadgeVariant.Outline;
  }
  // If the input is valid and has the correct length
  if (value.length === length && valid) {
    return BadgeVariant.Success;
  }
  // Otherwise
  return BadgeVariant.Danger;
};


/**
 * Handle form submission.
 * This function is called when the form is submitted.
 * It updates the camera settings and displays a success notification.
 *
 * @returns {Promise<void>}
 */
const handleSubmitForm = handleSubmit(async (): Promise<void> => {
  // Set the submitting state to true
  isSubmitting.value = true;

  // Check if the user is in admin mode
  if (!adminMode.value) {
    return;
  }

  // Create the request body for updating the camera
  const updatedCameraRequest: client.UpdateCameraRequest = {
    serialNumber: serialNumber.value as string,
    meta: {
      wpImei: wpImei.value as string,
      wpSerialNumber: wpSerialNumber.value as string,
    },
  };

  try {
    // Update the camera
    currentCamera.value = await client.updateCameraById({
      cameraId: cameraId.value,
      requestBody: updatedCameraRequest,
    }) as client.Camera;

    // Display a success notification
    applicationStore.publishSuccessNotification({
      text: 'Camera settings updated successfully.',
      autoCloseMs: 3000,
    });

    // Reset the touched state after successful submission
    // This disables the submit button and retains the input values
    resetSerialNumber({ value: serialNumber.value });
    resetWpSerialNumber({ value: wpSerialNumber.value });
    resetWpImei({ value: wpImei.value });

  } catch (error: unknown) {
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    console.error('Error creating camera:', errorMessage);

    applicationStore.publishErrorNotification({
      text: `Error creating camera: ${errorMessage}`,
    });

  } finally {
    isSubmitting.value = false;
  }
});

/**
 * Fetches the current camera data when the component is mounted and prepopulates the form fields.
 */
onMounted(async () => {
  // Set the loading state to true
  isLoading.value = true;

  try {
    // Fetch the current camera data from the API
    currentCamera.value = await client.getCameraById({ cameraId: cameraId.value });

    // Define keys for meta properties
    // These keys are not returned by the API, so we define them manually
    const wpSerialNumberKey = 'wpSerialNumber';
    const wpImeiKey = 'wpImei';

    // Prepopulate form fields with current camera data
    // Use the OR operator to provide a default value if the property is null or undefined
    status.value = currentCamera.value.status || '';
    systemType.value = currentCamera.value.systemType || '';
    serialNumber.value = currentCamera.value.serialNumber || '';
    wpSerialNumber.value = (currentCamera.value.meta?.[wpSerialNumberKey] as string) || '';
    wpImei.value = (currentCamera.value.meta?.[wpImeiKey] as string) || '';
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <template v-if="adminMode">
    <SubHeader heading="Edit Camera"
               level="2" />

    <Breadcrumb :is-sticky="true"
                :items="breadcrumbs" />

    <ContainerCard>
      <Loading v-if="isLoading" />

      <section v-else>
        <form @submit="handleSubmitForm">
          <div class="field-group">
            <div class="field-group-info">
              <Heading level="3">
                Edit Camera Information
              </Heading>
              <p>Update the details of the camera.</p>
            </div>

            <div class="fields">
              <div class="row-half">
                <div class="field">
                  <label for="status">Camera Status</label>
                  <input id="status"
                         v-model="status"
                         type="text"
                         placeholder="Camera Status"
                         disabled>
                </div>
                <div class="field">
                  <label for="systemType">System Type</label>
                  <input id="systemType"
                         v-model="systemType"
                         type="text"
                         disabled>
                </div>
              </div>

              <div class="row-half">
                <div class="field">
                  <div class="d-flex align-center justify-between">
                    <label for="serialNumber">GabrielCam Serial Number</label>
                    <BadgeComponent :variant="getBadgeVariant({ value: serialNumber, length: serialNumberLength, valid: serialNumberMeta.valid })"
                                    :is-pill="true">
                      {{ serialNumber?.length || 0 }}|{{ serialNumberLength }}
                    </BadgeComponent>
                  </div>
                  <input id="serialNumber"
                         v-model="serialNumber"
                         type="text"
                         placeholder="GC-1234-5678-9"
                         :maxlength="serialNumberLength"
                         :class="{ 'input-error': serialNumberMeta.touched && !serialNumberMeta.valid, 'input-valid': serialNumberMeta.valid }"
                         @input="serialNumberMeta.touched = true">
                  <ErrorMessage name="serialNumber" class="message message-error" as="p" />
                </div>
                <div class="field" />
              </div>

              <div class="row-half">
                <div class="field">
                  <div class="d-flex align-center justify-between">
                    <label for="wpSerialNumber">Modem Serial Number</label>
                    <BadgeComponent :variant="getBadgeVariant({ value: wpSerialNumber, length: wpSerialNumberLength, valid: wpSerialNumberMeta.valid })"
                                    :is-pill="true">
                      {{ wpSerialNumber?.length || 0 }}|{{ wpSerialNumberLength }}
                    </BadgeComponent>
                  </div>
                  <input id="wpSerialNumber"
                         v-model="wpSerialNumber"
                         type="text"
                         placeholder="12345678901234"
                         :maxlength="wpSerialNumberLength"
                         :class="{ 'input-error': wpSerialNumberMeta.touched && !wpSerialNumberMeta.valid, 'input-valid': wpSerialNumberMeta.valid }"
                         @input="wpSerialNumberMeta.touched = true">
                  <ErrorMessage name="wpSerialNumber" class="message message-error" as="p" />
                </div>

                <div class="field">
                  <div class="d-flex align-center justify-between">
                    <label for="wpImei">Modem IMEI</label>
                    <BadgeComponent :variant="getBadgeVariant({ value: wpImei, length: wpImeiLength, valid: wpImeiMeta.valid })"
                                    :is-pill="true">
                      {{ wpImei?.length || 0 }}|{{ wpImeiLength }}
                    </BadgeComponent>
                  </div>
                  <input id="wpImei"
                         v-model="wpImei"
                         type="text"
                         placeholder="123456789012345"
                         :maxlength="wpImeiLength"
                         :class="{ 'input-error': wpImeiMeta.touched && !wpImeiMeta.valid, 'input-valid': wpImeiMeta.valid }"
                         @input="wpImeiMeta.touched = true">
                  <ErrorMessage name="wpImei" class="message message-error" as="p" />
                </div>
              </div>
            </div>
          </div>

          <ButtonContainer>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :loading="isSubmitting"
                             :disabled="!meta.touched || !meta.valid"
                             :is-block-btn="true"
                             :type="ButtonType.Submit">
              Update
            </ButtonComponent>
          </ButtonContainer>
        </form>
      </section>
    </ContainerCard>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>

<style lang="scss" scoped>
:deep(.badge) {
  min-width: 48px;
}
</style>
