<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import 'dayjs/locale/en';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import IrisViewer from '@components/viewer/IrisViewer.vue';

const route = useRoute();
const viewId: string = route.params['id'] as string;
const view = ref({}) as Ref<client.View>;
const loading = ref<boolean>(true);

onMounted(async () => {
  loading.value = true;
  view.value = await client.getViewById({ viewId });
  loading.value = false;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="loading" />
    <IrisViewer v-else :view="view" />
  </ContainerCard>
</template>
