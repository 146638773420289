<template>
  <div class="map-directory">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.map-directory {
  display: flex;
  flex-direction: column;
  gap: $gap-desktop;
  align-items: flex-start;

  @media screen and (width >= $breakpoint-xl) {
    flex-direction: row;
  }
}
</style>
