<script lang="ts" setup>
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import IrisViewer from '@components/viewer/IrisViewer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';
import { storeToRefs } from 'pinia';
import { useViewStore } from '@stores/view';

const viewStore = useViewStore();
const route = useRoute();
const viewId: string = route.params['id'] as string;
const view = ref({}) as Ref<client.View>;
const isLoading = ref<boolean>(true);
const { datePickerIsOpen } = storeToRefs(viewStore);

onMounted(async () => {
  console.log('AAA');
  isLoading.value = true;
  view.value = await client.getViewById({ viewId });
  isLoading.value = false;
});

function toggleDatePicker(): void {
  datePickerIsOpen.value = !datePickerIsOpen.value;
}
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    <template v-else>
      <div class="d-lg-none w-100 mb-20">
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         @click="toggleDatePicker">
          Open Calendar
        </ButtonComponent>
      </div>

      <IrisViewer :view="view" />
    </template>
  </ContainerCard>
</template>
