<script setup lang="ts">
import { Ref, ref, watch } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DatePicker } from 'v-calendar';

// Date Picker Interface
interface HighlightedDates {
  key: string;
  highlight?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink' | {
    color?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
    fillMode?: 'solid' | 'light' | 'outline';
  };
  dot?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple' | 'pink';
  order?: number;
  dates: string[];
}

const props = defineProps<{
  availableDates: string[];
  modelValue: Date;
}>();

const calendar = ref();
const imagesCapturedOn: Ref<HighlightedDates[]> = ref([]);
const invalidDates: Ref<string[]> = ref([]);

watch(
  () => props.availableDates,
  async () => {
    calenderAttributes();
    disabledDates();

    if (!calendar.value) return;
    const dayToMoveTo = props.availableDates[props.availableDates.length - 1];
    if (!dayToMoveTo) return;
    selectedDate.value = new Date(dayToMoveTo);
    await calendar.value.move(dayToMoveTo); //Got to do this because the picker nav gets disabled
  }
);
const selectedDate = ref<Date>(props.modelValue);

const emit = defineEmits<{
  (e: 'dateSelected'): void;
  (e: 'update:modelValue', value: Date): void;
}>();

function onUpdateDate(e: string | number | Date | dayjs.Dayjs | null | undefined): void {
  if (e) {
    emit('update:modelValue', dayjs(e).startOf('day').toDate());
    emit('dateSelected');
  }
}

function calenderAttributes(): void {
  const validDates = props.availableDates.filter((date) => dayjs(date).isValid());
  imagesCapturedOn.value = [
    {
      key: 'availableDates',
      highlight: {
        color: 'green',
        fillMode: 'light',
      },
      order: -1,
      dates: validDates.map((date) => dayjs(date).format('YYYY-MM-DD')),
    }
  ];
}

/**
 * Generates a list of disabled dates by excluding available dates within the range of min and max dates.
 */
function disabledDates(): void {
  if (props.availableDates.length === 0) {
    invalidDates.value = [];
    return;
  }

  // Define the range based on availableDates
  const minDate = dayjs(props.availableDates[0]); // First available date
  const maxDate = dayjs(props.availableDates.at(-1)); // Last available date

  // Use a Set for faster lookup
  const availableDatesSet = new Set(
    props.availableDates.map((date) => dayjs(date).format('YYYY-MM-DD'))
  );

  // Generate dates only within the valid range
  const allPossibleDates = generateAllPossibleDates(minDate, maxDate);

  // Filter out available dates
  invalidDates.value = allPossibleDates.filter((date) => !availableDatesSet.has(date));
}

/**
 * Generates a list of all possible dates between the specified start and end dates.
 *
 * @param {dayjs.Dayjs} start - The start date of the range
 * @param {dayjs.Dayjs} end - The end date of the range
 * @returns {string[]} An array of dates in the format 'YYYY-MM-DD'
 */
function generateAllPossibleDates(start: dayjs.Dayjs, end: dayjs.Dayjs): string[] {
  const allDates = [];

  for (let current = start; current.isBefore(end) || current.isSame(end, 'day'); current = current.add(1, 'day')) {
    allDates.push(current.format('YYYY-MM-DD'));
  }
  return allDates;
}
</script>

<template>
  <DatePicker ref="calendar"
              v-model="selectedDate"
              :input-debounce="500"
              :attributes="imagesCapturedOn"
              :disabled-dates="invalidDates"
              title-position="left"
              :expanded="true"
              color="green"
              :first-day-of-week="2"
              mode="date"
              :min-date="props.availableDates[0]"
              :max-date="props.availableDates.at(-1)"
              @update:model-value="onUpdateDate" />
</template>
